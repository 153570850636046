import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'aws-amplify/utils';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import CountUp from "react-countup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
//import NavigationSelector from '../../Components/NavigationSelector/NavigationSelector';
//import DateChooser from '../../Components/DateChooser/DateChooser';
import DateChooser from "../../Components/DateChooser/DateChooser";
import BoxSelector from '../../Components/BoxSelector/BoxSelector';
import SyncIndicator from '../../Components/SyncIndicator/SyncIndicator';
import TotalInventory from "../../Components/TotalInventory/TotalInventory";
import InventoryCard from "../../Components/InventoryCard/InventoryCard";
import InventoryExtendedCard from "../../Components/InventoryExtendedCard/InventoryExtendedCard";
import Identity from "../../Components/Identity/Identity";
import {fundLocation, fundLocationTranslations, fundType, fundTypeTranslations,} from "../../../Models/Report/Enums.js";
import LevelManager from "../../../Utils/LevelManager";
import UUID from "../../../Utils/UUID";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";
import '../Operation/Operation.css';
import './Inventory.css';
import {getLatestInventory} from "../../../graphql/queries";

I18n.putVocabularies( fundLocationTranslations );
I18n.putVocabularies( fundTypeTranslations );

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const factoryUUID = new UUID();

let instanceInventory = null;
class Inventory extends React.Component {

	constructor( props ) {
		super( props );
		this.dateIsUserChoice = false;
		let value = 0;

		if ( this.props.optionalNavigationData ) {
			value = this.getBoxIndex( this.props.optionalNavigationData );
		}
		this.state = {
			isLoading:this.props.isLoadingInventory,
			value:value,
			date: new Date( new Date().getTime() /*- this.props.deltaTime*/ ).getTime(),
			forceRefresh:false,
			payStations: (props.payStations) ? JSON.parse( JSON.stringify( props.payStations ) ) : []
			//inventories:props.inventories
		}
		instanceInventory = this;
		instanceInventory.inventories = props.inventories;
		this.finder = new PayStationFinder( this.state.payStations );
	}

	onNewInventory( inventory ) {
		const selectedBox = this.state.payStations.sort( ( a , b ) => a.box.name.localeCompare( b.box.name ) )[ this.state.value ];
		if( selectedBox && selectedBox.devices ) {
			selectedBox.devices.forEach( device => {
				if( device.latestInventory?.cbms === inventory.cbms ) {
					device.latestInventory = inventory;
					this.setState( { date: new Date().getTime() } );
				}
			} );
		}
	}

	getBoxIndex( box ) {
		let index = 0;
		if ( this.props.boxList !== null &&  this.props.boxList !== undefined ) {
			this.props.boxList.map( (availableBox) => {
				if ( availableBox.id === box.id ) {
					index = this.props.boxList.indexOf(availableBox);
				}
				return true;
			} )
		}
		return index;
	}

	hasReceivedInventoryForActive( data ) {
		return false;
	}

	updateInventories( data ) {
		if( instanceInventory.hasReceivedInventoryForActive( data ) ) {
			if ( instanceInventory.dateIsUserChoice ) {
				this.setState( {
					isLoading:this.state.isLoading,
					value:this.state.value,
					date: this.state.date,
					//inventories:data
				} );
			} else {
				this.setState( {
					isLoading:this.state.isLoading,
					value:this.state.value,
					date: new Date(new Date().getTime() - instanceInventory.props.deltaTime ).getTime(),
					//inventories:data
				} );
			}
		}
		instanceInventory.inventories = data;
	}

	setValue( newValue ) {
		instanceInventory.setState({
			isLoading:this.state.isLoading,
			value:newValue,
			date: this.state.date,
			//inventories:this.state.inventories
		});
	}

	onLoadingStateUpdated( isLoading ) {
		instanceInventory.setState({
			isLoading:isLoading,
			value:instanceInventory.state.value,
			date: instanceInventory.state.date,
			inventories:instanceInventory.state.inventories
		});
	}

	handleChange(event, newValue) {
		instanceInventory.setValue(newValue);
	};

	handleDateSelection( newDate ) {
		instanceInventory.dateIsUserChoice = false;
		instanceInventory.state.payStations.forEach( payStation => {
			instanceInventory.loadLatestBoxInventory( payStation , new Date( newDate ).toISOString() );
		} );
	}

	loadLatestBoxInventory( payStation , requestDate = new Date().toISOString() ) {
		return new Promise( resolve => {
			if( payStation.devices ) {
				const loaded = [];
				payStation.devices.forEach( device => {
					if( instanceInventory.props.API ) {
						instanceInventory.props.API
							.graphql({ query: getLatestInventory, variables: {
									cbms: `5_${device.informations.bms}`,
									requestDate: requestDate
								} })
							.then( returned => {
								if( returned && returned.data && returned.data.getLatestInventory ) {
									device.latestInventory = returned.data.getLatestInventory;
								}
								loaded.push( device.informations.bms );
								if( loaded.length === payStation.devices.length ) {
									instanceInventory.setState({
										isLoading:instanceInventory.state.isLoading,
										value: instanceInventory.state.value,
										date: new Date( requestDate ).getTime(),
									});
									resolve();
								}
							})
							.catch((error) => {
								console.error("error" , error);
								loaded.push( device.informations.bms );
								if( loaded.length === payStation.devices.length ) {
									resolve();
								}
							})
					} else {
						resolve();
					}
				} )
			}
		} );
	}

	extractCurrentInventory( box ) {
		let returned = null;
		let inventoriesForBox = this.extractInventoriesForBox( box );
		if ( inventoriesForBox ) {
			let bestCandidate = { date:0 };
			let searchDate = new Date( this.state.date + (1000 * 60) ).getTime();
			inventoriesForBox.map( ( candidateInventory ) => {
				if ( candidateInventory && candidateInventory.hasOwnProperty("date") ) {
					if ( candidateInventory.date < searchDate ) {
						if ( candidateInventory.date >  bestCandidate.date ) {
							if( candidateInventory.inventory && candidateInventory.inventory.hasOwnProperty( "data" ) ) {
								if ( candidateInventory.inventory.data.locations.length > 0 ) {
									if ( box.hasOwnProperty("children") && box.children.length > 1 ) {
										if( box.mergers ) {
											box.mergers.map( ( merger ) => {
												if ( `5_${merger.bms_merger}` === candidateInventory.inventory.cbms ) {
													bestCandidate = candidateInventory;
												}
												return true;
											} );
										}
									} else {
										bestCandidate = candidateInventory;
									}
								}
							}
						}
					}
				}
				return true;
			} );
			returned = bestCandidate;
		}

		if ( box.hasOwnProperty("children") && box.children.length === 1 ) {
			let child = box.children[0];
			if( child !== null && child !== undefined ) {
				if( child.cbms.startsWith("5_Cashmag_VirtualDevice_") ) {
					returned = null;
				}
			} 
		}
		return returned;
	}

	extractInventoriesForBox( box ) {
		let returned = null;
		if ( instanceInventory.inventories !== null && instanceInventory.inventories !== undefined ) {
			instanceInventory.inventories.map( ( inventoriesGroupByBox ) => {
				if ( inventoriesGroupByBox !== null && inventoriesGroupByBox !== undefined && inventoriesGroupByBox.hasOwnProperty("id") ) {
					if ( inventoriesGroupByBox.id === box.id ) {
						if ( inventoriesGroupByBox.hasOwnProperty( "list" ) ) {
							returned = inventoriesGroupByBox.list;
						}
					}
				}
				return true;
			} );
		}
		return returned;
	}

	extractForLocation( inventories , location ) {
		const clone = JSON.parse( JSON.stringify( inventories ) );
		switch ( location ) {
			case fundLocation.RECYCLER :
				clone.forEach( candidate => {
					delete candidate.data.cashBox;
					delete candidate.data.loader;
				} );
				break;
			case fundLocation.CASHBOX1 :
			case fundLocation.CASHBOX2 :
				let hasNoCashBox = true;

				clone.forEach( candidate => {
					if( candidate.cbms.includes( "_MDB-CoinRecycler_" ) ||
						candidate.cbms.includes( "_SCR_" ) ||
						candidate.cbms.includes( "_BNR_" )) {
						delete candidate.data.details;
						hasNoCashBox = false;
					}

					if( hasNoCashBox ) {
						delete candidate.data.details;
						delete candidate.data.cashBox;
					}
					delete candidate.data.loader;
				} );
				break;
			case fundLocation.LOADER :
				clone.forEach( candidate => {
					delete candidate.data.details;
					delete candidate.data.cashBox;
				} );
				break;
			default : return null;
		}

		return clone;
	}

	extractForType( inventories , type ) {
		const filtered = [];
		if( inventories ) {
			inventories.forEach( inventory => {
				if( type.includes( fundType.CASH_COIN ) &&
					( inventory.cbms.includes( "_CLS_" ) ||
					  inventory.cbms.includes( "_MDB-CoinRecycler_" ) ) ) {
					filtered.push( inventory );
				}

				if( type.includes( fundType.CASH_NOTE ) &&
					( inventory.cbms.includes( "_SCR_" ) ||
					  inventory.cbms.includes( "_BNR_" ) ) ) {
					filtered.push( inventory );
				}
			} )
		}
		return filtered;
	}

	extractForLocationAndType( inventory , location , type ) {
		let filteredResults = [];
		if( inventory && inventory.inventories ) {
			try {
				filteredResults = this.extractForLocation( this.extractForType( inventory.inventories , type ) , location )
			} catch (exception) {
				console.warn(`extractForLocationAndType failure` , exception);
			}
		}

		filteredResults.forEach( resultToSort => {
			if( resultToSort.data.details ) {
				resultToSort.data.details.sort( ( a , b ) => a.value - b.value );
			}

			if( resultToSort.data.cashBox && resultToSort.data.cashBox.content ) {
				resultToSort.data.cashBox.content.sort( ( a , b ) => a.value - b.value );
			}

			if( resultToSort.data.loader && resultToSort.data.loader.content ) {
				resultToSort.data.loader.content.sort( ( a , b ) => a.value - b.value );
			}
		} );

		return filteredResults;
	}

	isVipsInventory( inventoryLocation , type ) {
		let isVips = true;
		if ( inventoryLocation !== null && inventoryLocation !== undefined && inventoryLocation.hasOwnProperty("details") ) {
			inventoryLocation.details.map( (line) => {
				if ( ! type.includes( line.fund_type ) ) {
					isVips = false;
				}
				return true;
			} );
		} else {
			isVips = false;
		}
		return isVips;
	}

	isActiveBoxAtSearchTime( box ) {
		let isActive = false;
		let searchDate = new Date( this.state.date + (1000 * 60) ).getTime();
		if( box !== null && box !== undefined && box.hasOwnProperty("periods") ) {
			box.periods.map( (period) => {
				if( period !== null && period !== undefined ) {
					try {
						let begin = new Date (Date.parse(period.begin));
						let end = new Date (Date.parse(period.end));
						
						if( isNaN( end.getTime() ) ) {
							end = new Date ( new Date().getTime() - ( new Date().getTimezoneOffset() * 60 * 1000 ) ); //currently opened period
						}
						
						if( searchDate >= begin.getTime() && end.getTime() >= searchDate ) {
							isActive = true;
						}
					} catch ( error ) {
						console.error( error );
					}
					
				} 
				return true;
			} );
		}
		if( ! this.props.inspector.isActive( box ) ) {
			isActive = false;
		}
		return isActive;
	}

	hasInventory( box ) {
		let inventory = this.extractCurrentInventory( box );
		return inventory !== null && inventory !== undefined && inventory.hasOwnProperty("inventory") && inventory.inventory !== null && inventory.inventory !== undefined;
	}

	formatAmount( amount , currencyCode ) {
		if( currencyCode === 'XPF' ) {
			return new Intl.NumberFormat( this.props.locale ).format( amount ) + ' ' + currencyCode;
		} else {
			return new Intl.NumberFormat( this.props.locale, { style: 'currency', currency: currencyCode }).format( amount );
		}
	}

	getCurrencyFormat( currencyCode ) {
		if( currencyCode === 'XPF' ) {
			return {
				locale: this.props.locale,
				separator: ' ',
				decimalSeparator: '',
				nbDecimal: 0,
				prefix: '',
				suffix: ' ' + currencyCode,
				currencyCode: currencyCode
			};
		}

		const numberWithGroupAndDecimalSeparator = 1000.1;
		const formatter = new Intl.NumberFormat( this.props.locale, { style: 'currency', currency: currencyCode });
		const formatted = formatter.format( numberWithGroupAndDecimalSeparator );
		const formattedParts = formatter.formatToParts(numberWithGroupAndDecimalSeparator);
		const parts = formatted.replace( formattedParts.find(part => part.type === "group")?.value , "" ).split( formattedParts.find(part => part.type === "decimal")?.value );
		const matchesDecimal = parts[1]?.match(/(\d+)/);
		const matchesPrefix = parts[0]?.match(/(\d+)/);
		const matchesSuffix = parts[1]?.match(/(\d+)/);
		let decimalLength = 0;
		let prefix = "";
		let suffix = "";
		if ( matchesDecimal ) {
			decimalLength = matchesDecimal[0].length;
		}

		if ( matchesPrefix ) {
			prefix = parts[0].replace( matchesPrefix[0] , "" );
		}

		if ( matchesSuffix ) {
			suffix = parts[1].replace( matchesSuffix[0] , "" );
		}

		return {
			locale:this.props.locale,
			separator:formattedParts.find(part => part.type === "group")?.value,
			decimalSeparator:formattedParts.find(part => part.type === "decimal")?.value,
			nbDecimal:decimalLength,
			prefix:prefix,
			suffix:suffix,
			currencyCode:currencyCode
		};
	}

	getHeaderProperties( data ) {
		if( data.length < 1 ) {
			return [];
		}

		return [
			{ id: "location", 		numeric: false, disablePadding: false, label: I18n.get("Location"), money: false, width: 125, 	align: 'center' },
			{ id: 'typeLocation',  	numeric: true,  disablePadding: false, label: I18n.get("Type"), 	money: false, width: 30, 	align: 'center' },
			{ id: 'valueInventory', numeric: true,  disablePadding: false, label: I18n.get("Value"), 	money: false, width: 105, 	align: 'right' },
			{ id: 'qtyInventory', 	numeric: true,  disablePadding: false, label: I18n.get("Quantity"), money: false, width: 100, 	align: 'right' },
			{ id: 'totalInventory', numeric: true,  disablePadding: false, label: I18n.get("Total"), 	money: true,  width: 130, 	align: 'right' }
		];

		/*return [
			{ id: "location", 		numeric: false, disablePadding: false, label: I18n.get("Location"), money: false, width: 80, 	align: 'center' },
			{ id: 'typeLocation',  	numeric: true,  disablePadding: false, label: I18n.get("Type"), 	money: false, width: 30, 	align: 'center' },
			{ id: 'valueInventory', numeric: true,  disablePadding: false, label: I18n.get("Value"), 	money: false, width: 65, 	align: 'center' },
			{ id: 'qtyInventory', 	numeric: true,  disablePadding: false, label: I18n.get("Quantity"), money: false, width: 60, 	align: 'center' },
			{ id: 'totalInventory', numeric: true,  disablePadding: false, label: I18n.get("Total"), 	money: true,  width: 65, 	align: 'center' },
			{ id: 'levelPrct', 		numeric: true,  disablePadding: false, label: I18n.get("Level"), 	money: false, width: 65, 	align: 'center' },
			{ id: 'levelColor', 	numeric: true,  disablePadding: false, label: I18n.get(""), 		money: false, width: 30, 	align: 'center' },
			{ id: 'toLow', 			numeric: true,  disablePadding: false, label: "", 					money: false, width: 40, 	align: 'center' },
			{ id: 'toNormal', 		numeric: true,  disablePadding: false, label: "", 					money: false, width: 40, 	align: 'center' },
			{ id: 'toHigh', 		numeric: true,  disablePadding: false, label: "", 					money: false, width: 40, 	align: 'center' },
		];*/
	}

	getDataCopyForPdf( data ) {
		const copy = JSON.parse( JSON.stringify( data ) );
		copy.totalPerType = {
			cash: {
				coins: 0,
				notes: 0,
				coinsQty: 0,
				notesQty: 0,
				total: 0
			}
		};
		copy.totalCashbox = {
			coins: 0,
			notes: 0,
			coinsQty: 0,
			notesQty: 0,
			total: 0
		};
		copy.total = 0;
		let latestDate = new Date( 0 ).getTime();
		const userOffsetMinutes = new Date().getTimezoneOffset();
		const userOffsetMilliseconds = userOffsetMinutes * 60000;

		data.inventories.forEach( inventory => {
			const cashKey = ( inventory.cbms.includes( '_SCR_' ) || inventory.cbms.includes( '_BNR_' ) ) ? 'notes' : 'coins';
			inventory.data.details.forEach( detail => {
				copy.totalPerType.cash[cashKey] += detail.value * detail.qty;
				copy.totalPerType.cash[cashKey + 'Qty'] += detail.qty;
				copy.total += detail.value * detail.qty;
				copy.totalPerType.cash.total += detail.value * detail.qty;
			} );

			if( inventory.data.cashBox && inventory.data.cashBox.content ) {
				inventory.data.cashBox.content.forEach( detail => {
					copy.totalPerType.cash[cashKey] += detail.value * detail.qty;
					copy.totalPerType.cash[cashKey + 'Qty'] += detail.qty;
					copy.totalPerType.cash.total += detail.value * detail.qty;
					copy.totalCashbox[cashKey] += detail.value * detail.qty;
					copy.totalCashbox[cashKey + 'Qty'] += detail.qty;
					copy.totalCashbox.total += detail.value * detail.qty;
					copy.total += detail.value * detail.qty;
				} );
			}

			const moduleDate = new Date( inventory.at ).getTime();
			if( moduleDate > latestDate ) {
				copy.date = new Date(moduleDate - userOffsetMilliseconds ).toISOString();
				latestDate = moduleDate;
			}
		} );

		return copy;
	}

	extractLatestInventory( payStation , maxDate ) {
		const boxInventory = {};
		if( payStation && payStation.devices && payStation.box ) {
			boxInventory.box = payStation.box;
			boxInventory.inventories = [];
			const finder = new PayStationFinder( this.props.data );
			const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
			boxInventory.boxExtraData = finder.buildTerminalExtraData( terminal );
			payStation.devices.forEach( device => {
				if( device.latestInventory ) {
					boxInventory.inventories.push( device.latestInventory );
				}
			} );
		}
		return boxInventory;
	}

	extractLatestInventoryDate( inventories ) {
		let latest = null;
		if( inventories ) {
			inventories.forEach( inventory => {
				if( !latest || new Date( inventory.at ).getTime() > new Date( latest ).getTime() ) {
					latest = inventory.at;
				}
			} );
		}
		return latest;
	}

	renderBox( data , alertParams ) {

		const coins = this.extractForLocationAndType( data , fundLocation.RECYCLER , [fundType.CASH_COIN] );
		const notes = this.extractForLocationAndType( data , fundLocation.RECYCLER , [fundType.CASH_NOTE] );
		const cashBoxNote = this.extractForLocationAndType( data , fundLocation.CASHBOX1 , [fundType.CASH_NOTE] );
		const cashBoxCoins = this.extractForLocationAndType( data , fundLocation.CASHBOX2 , [fundType.CASH_COIN] );
		const loader = this.extractForLocationAndType( data , fundLocation.LOADER , [fundType.CASH_NOTE] );
		/*const vouchers = this.extractForLocationAndType( data , fundLocation.CASHBOX1 , [fundType.RESTAURANT_VOUCHER ,
																							  fundType.RESTAURANT_VOUCHER_DEMATERIALIZED ,
																							  fundType.VACANCY_CHECK ,
																							  fundType.GIFT ,
																							  fundType.MISCELLANEOUS_CASHBOXED] );*/
		let optionalCoinCashBox = null;
		if( cashBoxCoins.length === 1 && cashBoxCoins[0].cbms?.includes( "_MDB-CoinRecycler_" ) ) {
			optionalCoinCashBox = this.renderInventory(cashBoxCoins , fundLocation.CASHBOX2 , fundLocation , alertParams , true);
		}

		let optionalLoader = null;
		if( loader.length === 1 && loader[0].data?.loader ) {
			optionalLoader = this.renderInventory(loader , fundLocation.LOADER , fundLocation , alertParams , true);
		}
		return (
			<React.Fragment key={factoryUUID.generate()}>
				{this.renderInventory(coins , fundType.CASH_COIN , fundType , alertParams , false) }
				{this.renderInventory(notes , fundType.CASH_NOTE , fundType , alertParams , false) }
				{optionalCoinCashBox}
				{this.renderInventory(cashBoxNote , fundLocation.CASHBOX1 , fundLocation , alertParams , true)}
				{optionalLoader}
				{/*this.renderInventory(vouchers, fundType.MISCELLANEOUS_CASHBOXED , fundType , alertParams , true)*/}
			</React.Fragment>
		);
	}

	renderBoxTotal( data , alertParams ) {
		const coins = this.extractForLocationAndType( data , fundLocation.RECYCLER , [fundType.CASH_COIN] );
		const notes = this.extractForLocationAndType( data , fundLocation.RECYCLER , [fundType.CASH_NOTE] );
		const cashBoxNote = this.extractForLocationAndType( data , fundLocation.CASHBOX1 , [fundType.CASH_NOTE] );
		const cashBoxCoins = this.extractForLocationAndType( data , fundLocation.CASHBOX2 , [fundType.CASH_COIN] );

		const loader = this.extractForLocationAndType( data , fundLocation.LOADER , [fundType.CASH_NOTE] );
		/*const vouchers = this.extractForLocationAndType( data , fundLocation.CASHBOX1 , [fundType.RESTAURANT_VOUCHER ,
																							  fundType.RESTAURANT_VOUCHER_DEMATERIALIZED ,
																							  fundType.VACANCY_CHECK ,
																							  fundType.GIFT ,
																							  fundType.MISCELLANEOUS_CASHBOXED] );*/
		let optionalCoinCashBox = null;
		if( cashBoxCoins.length === 1 && cashBoxCoins[0].cbms?.includes( "_MDB-CoinRecycler_" ) ) {
			optionalCoinCashBox = this.renderInventoryTotal(cashBoxCoins , fundLocation.CASHBOX2 , fundLocation , alertParams , true);
		}

		let optionalLoader = null;
		if( loader.length === 1 && loader[0].data?.loader ) {
			optionalLoader = this.renderInventoryTotal(loader , fundLocation.LOADER , fundLocation , alertParams , true);
		}
		return (
			<React.Fragment key={factoryUUID.generate()}>
				{this.renderInventoryTotal(coins , fundType.CASH_COIN , fundType , alertParams , false) }
				{this.renderInventoryTotal(notes , fundType.CASH_NOTE , fundType , alertParams , false) }
				{optionalCoinCashBox}
				{this.renderInventoryTotal(cashBoxNote , fundLocation.CASHBOX1 , fundLocation , alertParams , true) }
				{optionalLoader}
				{/*this.renderInventoryTotal(vouchers, fundType.MISCELLANEOUS_CASHBOXED , fundType , alertParams , true)*/}
			</React.Fragment>
		);
	}

	renderInventory(data , type , enumeration , alertParams , extended ) {
		if( data ) {
			if( data.length > 0 ) {
				let iconType = enumeration.getIcon( type );
				let itemList = [];
				if( extended ) {
					itemList.push( this.renderCashBox( data[0] , iconType , type ) );
				} else {
					data.forEach( inventory => {
						if( inventory && inventory.data && inventory.data.details ) {
							inventory.data.details.forEach( line => {
								itemList.push( this.renderGauge( line , iconType, inventory ) );
							} );
						}
						return true;
					} );
				}

				const classes = {
					paper:"no-shadow" ,
					totalCard:""
				}

				return (
					<Paper className={classes.paper} >
						<Typography component="h6" variant="h6" color="primary" gutterBottom>
							{I18n.get( enumeration.getName(type) )}
						</Typography>
						<Grid container spacing={1} alignItems="center">
							<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className="cm-data-box p-2">
								<Grid container spacing={1}>
									{ itemList.map( item => item ) }
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				);
			}
		}
		return null;
	}

	renderInventoryTotal(data , type , enumeration , alertParams , extended ) {
		if( data != null ) {
			if( data.length > 0 ) {
				let manager = new LevelManager( data );
				let total = manager.getTotalAmount();
				if( total > 0 ) {
					let titleTotal = I18n.get( "Total" ) + " " + I18n.get( enumeration.getName(type) ).toLowerCase();
					let currency = data[0].currency;
					if( ! currency ) {
						currency = manager.getCurrencyList()[0];
					}
					const amount = this.renderAmountCountUp( total , currency , alertParams );//formatter.format( total )  + " " + data[0].detail.currency;
					let totalBlock;
					if( extended ) {
						totalBlock = this.renderTotalBox( titleTotal , amount , alertParams , data, type );
					} else {
						totalBlock = this.renderTotalRecycler( titleTotal , amount , alertParams );
					}

					return (
						<React.Fragment>
							{totalBlock}
						</React.Fragment>
					);
				}
			}
		}
		return null;
	}

	renderAmountCountUp( amount , currencyCode , alertParams ) {
		let currencyFormat = this.getCurrencyFormat( ( currencyCode && currencyCode.trim().length > 0 ) ? currencyCode : "EUR" );
		let cssText = "display-4 font-weight-bold text-" + alertParams.getCssType();
		if( alertParams.level < 0 ) {
			//override to show disabled alarm
			cssText = "display-4 font-weight-bold text-disabled";
		} 
		return (
			<CountUp
				start={0}
				end={amount}
				duration={2}
				deplay={0}
				separator={currencyFormat.separator}
				decimals={currencyFormat.nbDecimal}
				decimal={currencyFormat.decimalSeparator}
				prefix={currencyFormat.prefix}
				suffix={currencyFormat.suffix}
				className={cssText}
			/>
		);
	}

	renderGauge( line , iconType, inventory ) {
		if( line ) {
			return (
				<Grid item xs={12} md={12} lg={6} key={factoryUUID.generate()} >
					{<InventoryCard line={line} iconType={iconType} inventory={inventory} locale={this.props.locale}/>}
				</Grid>
			);
		}
		return null;
	}

	renderCashBox( inventory , iconType , location ) {
		if( inventory != null ) {
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} >
					{<InventoryExtendedCard key={factoryUUID.generate()} type={location} iconType={iconType} inventory={inventory} locale={this.props.locale}/>}
				</Grid>
			);
		}
		return null;
	}

	renderTotalRecycler( type , amount , alertParams ) {
		let alarmCss;
		let totalCss;
		if( alertParams.currentAmount > alertParams.level ) {
			alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-danger text-white btn-icon mx-auto text-center shadow-danger pulse-animation";
			totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg text-danger"
		} else {
			if( alertParams.currentAmount > ( alertParams.level - alertParams.warningAmount ) ) {
				alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-warning text-white btn-icon mx-auto text-center shadow-warning font-size-lg pulse-animation slow";
				totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg text-warning"
			} else {
				alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-success text-white btn-icon mx-auto text-center shadow-success font-size-lg";
				totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg"
			}

		}
		
		if( alertParams.level < 0 ) {
			//override to show disabled alarm
			alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-disabled text-white btn-icon mx-auto text-center shadow-disabled font-size-lg";
			totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg";
		} 

		return (
			<Card className="card-box card-box-alt card-box-total-cm extra-mt">
				{this.renderAlertPct(alertParams)}
				<div className="card-content-overlay text-center pb-4">
					<div className={alarmCss}>
						<FontAwesomeIcon icon={alertParams.getIcon()} className="display-4" />
					</div>
					<div className={totalCss}>
						{amount}
					</div>
					<div className="font-size-lg opacity-8">
						{type}
					</div>
				</div>
			</Card>
		);
	}

	renderTotalBox( title , amount , alertParams , data , type ) {
		let alarmCss;
		let totalCss;

		if( alertParams.currentAmount > alertParams.level ) {
			alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-danger text-white btn-icon mx-auto text-center shadow-danger pulse-animation";
			totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg text-danger";
		} else {
			if( alertParams.currentAmount > ( alertParams.level - alertParams.warningAmount ) ) {
				alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-warning text-white btn-icon mx-auto text-center shadow-warning font-size-lg pulse-animation slow";
				totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg text-warning";
			} else {
				alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-success text-white btn-icon mx-auto text-center shadow-success font-size-lg";
				totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg";
			}
		}

		if( alertParams.level < 0 ) {
			//override to show disabled alarm
			alarmCss = " small-cm d-50 rounded border-0 mb-1 card-icon-wrapper bg-disabled text-white btn-icon mx-auto text-center shadow-disabled font-size-lg";
			totalCss = "font-weight-bold text-black display-3 mt-4 mb-1 font-size-lg";
		} 
		
		const manager = new LevelManager( data );
		const currencies = manager.getCurrencyList();
		if( currencies.length === 1 && type !== fundType.MISCELLANEOUS_CASHBOXED ) {
			return this.renderTotalRecycler( title , amount , alertParams );
		} else {
			let itemList = [];
			if ( type === fundType.MISCELLANEOUS_CASHBOXED ) {
				Object.keys(fundType).forEach( (key) => {
					itemList.push( this.renderVipsTotal( data , fundType[key] ) );
				} );
			} else {
				currencies.forEach( (currency) => {
					itemList.push( this.renderCurrencyTotal( data , currency ) );
				} );
			}

			return (
				<Card className="card-box card-box-alt card-box-total-cm extra-mt">
					{this.renderAlertPct(alertParams)}
					<div className="card-content-overlay text-center pb-4">
						<div className={alarmCss}>
							<FontAwesomeIcon icon={alertParams.getIcon()} className="display-4" />
						</div>
						<div className={totalCss}>
							{amount}
						</div>
						<div className="font-size-lg opacity-8">
							{title}
						</div>
						{ itemList.map( (item) => item ) }
					</div>
				</Card>
			);
		}
	}

	renderCurrencyTotal( data , currency ) {
		let manager = new LevelManager( data );
		let total = manager.getTotalAmountForCurrency( currency );
		let totalRootCss = "";
		return (
			<div className={totalRootCss} key={factoryUUID.generate()}>
				{new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: currency }).format(total)}
			</div>
		);
	}

	renderVipsTotal( data , type ) {
		let supported = [fundType.RESTAURANT_VOUCHER ,
			fundType.RESTAURANT_VOUCHER_DEMATERIALIZED ,
			fundType.VACANCY_CHECK ,
			fundType.GIFT];
		if ( supported.includes( type ) ) {
			let manager = new LevelManager( data );
			let total = manager.getTotalAmountForType( type );
			if ( total > 0 ) {
				let totalRootCss = "";
				return (
					<div className={totalRootCss} key={factoryUUID.generate()}>
						<span className="mx-1">{I18n.get( fundType.getName(type) )} : </span>
						<span className="">{new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: "EUR" }).format(total)}</span>
					</div>
				);
			}
		}
		return null;
	}

	renderAlertPct( alertParams ) {
		if( alertParams.warningAmount < 0 || ! alertParams.warningAmount ) {
			return null;
		}
		let label = "";
		let css;
		if( alertParams.currentAmount === alertParams.level ) {
			label = this.formatAmount( 0.00 , alertParams.currency );//new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: alertParams.currency }).format( 0.00 );
			css = "badge badge-neutral-warning text-warning";
		} else {
			if( alertParams.currentAmount > ( alertParams.level - alertParams.warningAmount ) ) {
				css = "badge badge-neutral-warning text-warning";
				let percentage = (alertParams.currentAmount/alertParams.level) * 100;
				let diff = alertParams.currentAmount - alertParams.level;
				if( percentage < 100 ) {
					label += "";
				} else {
					label += "+";
				}
				label += this.formatAmount( diff , alertParams.currency );//new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: alertParams.currency }).format( diff );

			} else {
				css = "badge badge-neutral-success text-success";
				let diff = alertParams.currentAmount - ( alertParams.level - alertParams.warningAmount );
				label += this.formatAmount( diff , alertParams.currency );//new Intl.NumberFormat(this.props.locale, { style: 'currency', currency: alertParams.currency }).format( diff );
			}
		}
		return (
			<div className="card-tr-actions">
				<div className={css}>
					{label}
				</div>
			</div>
		);
	}

	renderTabPanel( box , counterIndex , data , alertParams ) {
		let shown = false;
		const lastDate = instanceInventory.extractLatestInventoryDate( data.inventories );
		if( ( data && data.inventories && data.inventories.length < 1 ) || ! lastDate ) {
			shown = true;
		}

		let graphSize = 6;
		if ( this.props.isDesktop ) {
			graphSize = 7;
		}

		//OVERRIDE data for PDF
		if( counterIndex === instanceInventory.state.value ) {
			instanceInventory.props.pdfDataDelegate.columnDefinition = this.getHeaderProperties(data);
			instanceInventory.props.pdfDataDelegate.data = this.getDataCopyForPdf( data );
			instanceInventory.props.pdfDataDelegate.map = {};
			instanceInventory.props.pdfDataDelegate.subTitle = ( box.informations && box.informations.attributes && box.informations.attributes.name ) ? box.informations.attributes.name : box.name;
			instanceInventory.props.pdfDataDelegate.extraLabel = this.finder.extractCompanyShopLabel( data.boxExtraData );
			instanceInventory.props.pdfDataDelegate.inventoryDate = new Date().toLocaleString().replace("," , "").substring( 0 , new Date().toLocaleString().replace("," , "").length - 3 );
			//instanceInventory.props.pdfDataDelegate.endLocalizedDate = new Date(instanceInventory.state.period1.end).toLocaleString().replace("," , "").substring( 0 , new Date(instanceInventory.state.period1.end).toLocaleString().replace("," , "").length - 3 );
			const handlerExtraData = {
				locale: instanceInventory.props.locale.substring( 0 , instanceInventory.props.locale.indexOf("-") ),
				offset:0,
				data:instanceInventory.props.pdfDataDelegate.data,
				alertParams: alertParams
			}
			instanceInventory.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}

		const localizedInventoryDate = ( lastDate ) ? `${I18n.get("Last inventory at")} ${new Date( lastDate ).toLocaleString( this.props.locale ).replace("," , "")}` : '';
		const key = "backoffice-tab-panel-item-" + box.id;
		return (
			<TabPanel 	key={key}
						 value={instanceInventory.state.value}
						 index={counterIndex}
						 id={`scrollable-auto-tabpanel-${box.id}`}
						 aria-labelledby={`scrollable-auto-tab-${box.id}`} >
				<Grid container spacing={1} >
					<Grid id="exportable-reminder" container spacing={1}>
					</Grid>
					<Grid id="exportable-table" className="groupedBy" item xs={12} md={12-graphSize} lg={12-graphSize}>
						<div className="tab-panel-content">
							<Grid container spacing={1} >
								<Grid item xs={12} md={12} lg={12}>
									<Paper className="p-2">
										<Identity data={data} shown={this.props.user?.observer?.currentUser?.features?.INVENTORY_IDENTITY}/>
										<div className="inventoryDate">{localizedInventoryDate}</div>
										{<TotalInventory inventories={data.inventories}
														locale={this.props.locale}
														date={this.state.date}
														isLoadingInventory={instanceInventory.state.isLoading}
														alertParams={alertParams} />}
										{this.renderBoxTotal( data , alertParams )}
										<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
									</Paper>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} md={graphSize} lg={graphSize}>
						<Paper id="exportable-graphic" className="card-box mt-3">
							{this.renderBox( data , alertParams )}
						</Paper>
					</Grid>
				</Grid>
			</TabPanel>
		);

	}

	renderContent( panels ) {
		if( this.state.value >= 0 ) {
			return ( <React.Fragment>{panels.map( item => item )}</React.Fragment> );
		} 
		return null;
	}

	render() {
		const filteredPayStations = [];
		const boxes = [];
		const analyzer = new ConfigAnalyzer( this.state.payStations );
		this.state.payStations.forEach( payStation => {
			if( analyzer.isActiveBoxAtSearchTime( payStation ) && analyzer.isValid( payStation ) ) {
				filteredPayStations.push( payStation );
				this.extractLatestInventory( payStation , this.state.date );
				const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
				payStation.box.extraData = this.finder.extractCompanyShopLabel( this.finder.buildTerminalExtraData( terminal ) );
				boxes.push( payStation.box );
			}
		} );

		let tabs = [];
		let panels = [];
		let counterIndex = 0;
		this.allData = [];
		if ( ! boxes  || boxes.length < 1 ) {
			return (<NoDataPlaceholder className="comparison-placeholder-no-data" shown={true}/>);
		}

		filteredPayStations.sort( ( a , b ) => a.box.name.localeCompare( b.box.name ) );
		boxes.sort( ( a , b ) => a.name.localeCompare( b.name ) );
		filteredPayStations.forEach( payStation => {
			const data = this.extractLatestInventory( payStation , this.state.date );

			const manager = new LevelManager( data.inventories );
			const alertParams = {
				level:( this.props.user.userProperties.properties.alarmInventoryLevel && this.props.user.userProperties.properties.alarmInventoryLevel.trim() !== "" ) ? parseFloat( this.props.user.userProperties.properties.alarmInventoryLevel ) : -1,
				warningAmount:( this.props.user.userProperties.properties.warningInventoryLevel && `${this.props.user.userProperties.properties.warningInventoryLevel}`.trim() !== "" ) ? parseFloat( this.props.user.userProperties.properties.warningInventoryLevel ) : -1,
				currentAmount: manager.getTotalAmount(),
				currency: manager.getCurrencyList()[0],
				getIcon() {
					if( alertParams.level < 0 ) {
						return ['far', 'bell-slash'];
					}
					if( alertParams.currentAmount > ( alertParams.level - alertParams.warningAmount ) ) {
						return ['fa', 'exclamation'];
					}
					return ['far', 'bell'];
				},
				getCssType() {
					if( alertParams.currentAmount > alertParams.level ) {
						return "danger";
					} else {
						if( alertParams.currentAmount > ( alertParams.level - alertParams.warningAmount ) ) {
							return "warning";
						} else {
							return "black";
						}

					}
				},
			};
			tabs.push( payStation.box );
			panels.push( instanceInventory.renderTabPanel( payStation.box , counterIndex , data , alertParams ) );
			counterIndex++;
		} );

		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.selectedNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div id="exportable" className="z-over fullscreen">
					<div className="period mb-4">
						<SyncIndicator loading={this.state.isLoading}
						                className={"display-4 spining space-top"}/>
						<DateChooser date={this.state.date}
									 onDateChanged={this.handleDateSelection}
									 callbackIdentifier="period1"
									 isDarkStyle={this.props.isDarkStyle} />
					</div>
					
					<BoxSelector boxes={boxes}
								 allowEmpty={true}
								 label={I18n.get("Select box")}
								 selected={this.state.value} 
								 defaultValue={`${boxes[0].id}`}
								 showIcon={true}
								 isDarkStyle={this.props.isDarkStyle}
								 onChange={this.handleChange} />
					{this.renderContent(panels)}
				</div>
			</React.Fragment>
		);
	}
}

export default Inventory;
