import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	TextField,
	List,
	InputAdornment,
	ListItem,
	Tooltip ,
} from "@mui/material";
import { roles , getIconForNode } from '../../../Models/Roles.js';

import './LicenseManager.css';
import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ReactCountryFlag from "react-country-flag";
import DialogManageAccountLicenses from "./DialogManageAccountLicenses";
import {insertLog, setAccountOptions} from "../../../graphql/mutations";

let beforeEditCopy = null;
export default function LicenseManager( props ) {
	const [filterName, setFilterName] = React.useState( null );
	const [filterType, setFilterType] = React.useState( 0 );
	const [selected, setSelected] = React.useState( null );
	const [pendingEdit, setPendingEdit] = React.useState( null );

	const ROOT = 1;
	const RESELLER = 4;
	const CUSTOMER = 5;

	const onFilterNameChanges = evt => {
		setFilterName( evt.target.value );
	};

	const onFilterTypeChanges = evt => {
		setFilterType( evt.target.value );
	};

	const filterByName = list => {
		if( ! filterName ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( account => {
				if( account.name.includes( filterName ) ||
					account.code.includes( filterName ) ) {
					filtered.push( account );
				}
			} );
		}
		return filtered;
	}

	const filterByType = list => {
		if( ! filterType || filterType === 0 ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( account => {
				if( account.type === filterType ) {
					filtered.push( account );
				}
			} );
		}
		return filtered;
	};

	const filterPayStationByAccount = account => {
		const filtered = [];
		props.payStations?.forEach( payStation => {
			if( account.type === RESELLER ) {
				if( payStation?.box?.informations?.sender?.reseller_id === account.code ) {
					filtered.push( payStation );
				}
			} else if ( account.type === CUSTOMER ) {
				if( payStation?.box?.informations?.sender?.customer_code === account.code ) {
					filtered.push( payStation );
				}
			}
		} );
		return filtered;
	};

	const getLicensesFromOptions = account => {
		let options = null;
		try {
			options = JSON.parse( account.options.options );
			options = parseInt( options.licenses , 10 );
		} catch ( error ) {
			//unused
			options = 0;
		}
		return options;
	};

	const getOptionsForAccount = () => {
		if( selected.options ) {
			return selected.options;
		}

		return {
			creation: new Date().toISOString(),
			options: JSON.stringify( {
				licenses: 0,
				accountOffset: new Date().getTimezoneOffset()
			} ),
			updatedAt: new Date().toISOString(),
			accountId: selected.id
		}
	};

	const getOtherLicensesUsed = () => {
		let licenses = 0;
		props.accounts?.forEach( account => {
			if( account.id !== props.user.accountId && account.id !== selected.id ) {
				const toAdd = getLicensesFromOptions( account );
				if( toAdd > 0 ) {
					licenses += toAdd;
				}
			}
		} );
		return licenses;
	}

	const getAllLicensesUsed = () => {
		let licenses = 0;
		props.accounts?.forEach( account => {
			if( account.id !== props.user.accountId ) {
				const toAdd = getLicensesFromOptions( account );
				if( toAdd > 0 ) {
					licenses += toAdd;
				}
			}
		} );
		return licenses;
	}

	const handleClickManageLicenses = () => {
		const alreadyUsedByOtherAccounts = getOtherLicensesUsed();
		const licenses = getLicensesFromOptions( selected );
		beforeEditCopy = {licenses: `${licenses}` , maxLicenses: maxLicenses - alreadyUsedByOtherAccounts , accountId: selected.id };
		setPendingEdit( {licenses: `${licenses}` , maxLicenses: maxLicenses - alreadyUsedByOtherAccounts } );
	};

	const handleConfirm = licenses => {
		const clear = () => {
			setPendingEdit( null );
			setSelected( null );
		}
		if( props.API && licenses ) {
			const options = getOptionsForAccount();
			let params;
			try {
				params = JSON.parse( options.options );
			} catch ( errorParse ) {
				params = {
					licenses: options.licenses,
					accountOffset: new Date().getTimezoneOffset()
				};
			}
			params.licenses = licenses;
			const input = {
				creation: options.creation,
				options: JSON.stringify( params ),
				updatedAt: new Date().toISOString(),
				accountId: options.accountId
			};
			props.API.graphql({
				query: setAccountOptions,
				variables: {
					input: input
				}
			})
			.then( returned => {
				console.log( 'Account options saved' , returned );
				logDifferences( params );
				clear();
				props.onUpdate();
			})
			.catch((error) => {
				console.log("error" , error);
				clear();
			});
		} else {
			clear();
		}
	};

	const logDifferences = input => {
		if( props.API ) {
			props.API.graphql({
				query: insertLog,
				variables: {
					input: {
						userId: props.user.uuid,
						at: new Date().toISOString(),
						type: 'LICENSE',
						details: JSON.stringify( calculateChangesOnLicense( input ) )
					}
				}
			})
			.then( returned => {
				console.log( 'Account options logged' , returned );
			})
			.catch((error) => {
				console.log("error" , error);
			});
		}
		beforeEditCopy = null;
	};

	const calculateChangesOnLicense = input => {
		const unlimitedMode = input.licenses === '-1' || beforeEditCopy.licenses === '-1';
		const mouvementFromUnlimitedToNumbered = ( beforeEditCopy.licenses === '-1' ) ? input.licenses : 0;
		const mouvementFromNumberedToUnlimited = ( unlimitedMode ) ? mouvementFromUnlimitedToNumbered : input.licenses - beforeEditCopy.licenses;
		return {
			oldValue: parseInt( beforeEditCopy.licenses , 10 ),
			newValue: parseInt( input.licenses , 10 ),
			movement: mouvementFromNumberedToUnlimited,
			accountId: beforeEditCopy.accountId
		};
	};

	const renderHeader = () => {
		const available = maxLicenses - getAllLicensesUsed();
		const label = ( maxLicenses === -1 ) ? I18n.get("Unlimited") : `${available} / ${maxLicenses}`;
		return (
			<div className={`header-admin`}>
				<div className={`action-bar`}>
					<div className={`title`}>{ I18n.get("Accounts") }</div>
					<div className={`count`}>({ ( props.accounts ) ? props.accounts.length : 0 })</div>
					<div className={`spacer`}></div>
					<div className={`extra-title`}>{ I18n.get("Available licenses") } : {label} </div>
					<div className={`spacer`}></div>
					<div className={`actions`}>
						<div className={`button ${( ! selected )? 'disabled':''}`}  onClick={handleClickManageLicenses}>
							{ I18n.get("Manage Licenses") }
						</div>
					</div>
				</div>
				<div className={`filter-bar`}>
					<TextField id="search-installations"
							   className={`search-field-expand`}
							   label={ I18n.get("Filter account by name") }
							   variant="outlined"
							   size="small"
							   InputProps={{
								   startAdornment: (
									   <InputAdornment position="start">
										   <Search />
									   </InputAdornment>
								   ),
							   }}
							   onChange={ onFilterNameChanges }/>
					<div className={`spacer`}></div>
					<FormControl variant="outlined" className={`search-field-medium`} size={`small`}>
						<InputLabel className={``}
									id={`user-filter-role-input`}>
							{I18n.get("Filter by roles")}
						</InputLabel>
						<Select labelId={`account-filter-type-label`}
								id={`account-filter-type`}
								value={filterType}
								onChange={onFilterTypeChanges}
								MenuProps={{
									classes:{paper:`${(props.isDarkStyle) ? 'dark' : ''}`}
								}}
								label={I18n.get("Filter by roles")} >
							<MenuItem value={0}>
								<FontAwesomeIcon icon={["fas" , "users"]} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("All")}</span>
							</MenuItem>
							<MenuItem value={RESELLER}>
								<FontAwesomeIcon icon={getIconForNode( {role: roles.RESELLER} )} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("Reseller")}</span>
							</MenuItem>
							<MenuItem value={CUSTOMER}>
								<FontAwesomeIcon icon={getIconForNode( {role: roles.CUSTOMER} )} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("Customer")}</span>
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
		);
	};

	const renderListItem = account => {
		const licenses = getLicensesFromOptions( account );
		const activePayStations = filterPayStationByAccount( account ).length;

		return (
			<ListItem key={account.id}
					  className={`payStation-card ${(selected === account)?'selected':''}`}
					  onClick={() => { setSelected( account ); }}>
				<div className={`type-block`}>
					<FontAwesomeIcon icon={getIconForNode( {role: account.type} )} style={{fontSize:"1.25em"}}/>
					<div className={`name`}> { account.name } </div>
				</div>
				<div className={`type-block`}>
					<div className={`code`}>  </div>
				</div>
				<div className={`count-block`}>

				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon={`fa-solid fa-key`} style={{fontSize:"1.25em"}}/>
					<div> {(licenses === -1) ? <FontAwesomeIcon icon="fa-solid fa-infinity" /> : licenses } </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon={getIconForNode( {role: roles.BOX} )} style={{fontSize:"1.25em"}}/>
					<div> {`${activePayStations} `} </div>
				</div>
				<div className={`count-block ${ ( account.active ) ? ( licenses >= activePayStations || licenses === -1 ? "success" : "warning" ) : '' }`} >
					{ account.active ?
						( licenses >= activePayStations || licenses === -1 ?
								<FontAwesomeIcon icon="fa-solid fa-circle-check" style={{fontSize:"1.25em"}}/> :
								<FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style={{fontSize:"1.25em"}}/>
						) :
						<FontAwesomeIcon icon={["fas" , "store-slash"]} style={{fontSize:"1.25em"}}/> }
				</div>
				<div className={`locale`} >
					<ReactCountryFlag
						countryCode={account.locale.toUpperCase()}
						svg
						cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
						cdnSuffix="svg"
						title={account.locale}
					/>
				</div>
			</ListItem>
		);
	};

	let filtered;
	let maxLicenses = 0;
	const items = [];

	filtered = filterByType( filterByName( props.accounts ) );
	filtered?.forEach( account => {
		if( account.id === props.user.accountId ) {
			maxLicenses = getLicensesFromOptions( account );
		} else {
			items.push( renderListItem( account ) );
		}
	} );

	const shown = filtered.length < 1;
	if( shown && selected ) {
		setSelected( null );
	}

	return (
		<React.Fragment>
			<div id="installation-manager">
				{renderHeader()}
				<List>
					{items.map( item => item )}
				</List>
				<NoDataPlaceholder className="comparison-placeholder-no-data"
								   shown={shown}/>
				<DialogManageAccountLicenses handleClose={handleConfirm}
											pendingEdit={pendingEdit}
											isDarkStyle={props.isDarkStyle}/>
			</div>
		</React.Fragment>
	);
}
