import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from "@mui/material/TextField";
import {I18n} from "aws-amplify/utils";
import {Box, Popover} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SelectHidingMenu from "./SelectHidingMenu";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import './UserFilteringEngine.css';
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import { styled } from '@mui/material/styles';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import UUID from "../../../Utils/UUID";
import {getDetailedAccounts} from "../../../graphql/queries";
import GraphQlTool from "../../../Utils/GraphQlTool";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import DialogConfirmRemove from "../DialogConfirmRemove/DialogConfirmRemove";
import {roles, getIconForNode, UserRoles} from '../../../Models/Roles.js';
import {fundType} from "../../../Models/Report/Enums";
import PayStationFinder from "../../../Utils/PayStationFinder";

const factoryUUID = new UUID();
const RESELLER = 4;
const CUSTOMER = 5;
let isNewFilter = false;
let editingFilterName = null;
let shouldNotify = false;
let isClearRequested = false;
let previousReseller = null;
let previousCustomer = null;

const UserFilteringEngine = props => {
    const filterIndex = parseInt( props?.user?.userProperties?.properties?.currentFilter , 10 );
    const [isSimpleMode, setIsSimpleMode] = React.useState( true );
    const [anchorEl, setAnchorEl] = React.useState( props.isSearchMenuOpened );
    const [currentSearch, setCurrentSearch] = React.useState(null);
    const [filterName, setFilterName] = React.useState(null);
    const [currentFilter, setCurrentFilter] = React.useState(( props?.user?.userProperties?.properties?.currentFilter ) ? props?.user?.userProperties?.properties?.currentFilter : '-1');
    const [isEditing, setIsEditing] = React.useState(false);
    const [pendingDelete, setPendingDelete] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState(( props?.user?.userProperties?.properties?.currentFilter &&
        props?.user?.userProperties?.properties?.currentFilter !== '-1' &&
        props?.user?.userProperties?.filters[ filterIndex ] ) ? props?.user?.userProperties?.filters[ filterIndex ].selection : []);
    const [selectedDynamicItems, setSelectedDynamicItems] = React.useState( []);
    const [expandedItems, setExpandedItems] = React.useState(( props?.user?.userProperties?.properties?.currentFilter &&
        props?.user?.userProperties?.properties?.currentFilter !== '-1' &&
        props?.user?.userProperties?.filters[ filterIndex ] ) ? props?.user?.userProperties?.filters[ filterIndex ].expanded : []);
    const [accounts, setAccounts] = React.useState( null );
    const [reseller, setReseller] = React.useState( '-1' );
    const [customer, setCustomer] = React.useState( '-1' );
    const configAnalyzer = new ConfigAnalyzer( props.payStations );

    const extractCompanyShopLabel = fullLabel => {
        const firstIndex = fullLabel.indexOf('|');
        if (firstIndex === -1) {
            return fullLabel;
        }

        const secondIndex = fullLabel.indexOf('|', firstIndex + 1);
        if (secondIndex === -1) {
            return fullLabel;
        }

        return fullLabel.substring(0, secondIndex);
    }

    const filter = () => {
        if( isSimpleMode ) {
            return filterForSimpleMode();
        }
        return filterForAdvancedMode();
    }

    const filterForSimpleMode = () => {
        const filtered = [];
        props.payStations?.forEach( payStation => {
            if( selectedDynamicItems.some( item => item.id === payStation.box?.id ) ) {
                filtered.push( payStation );
            }
        } );
        return filtered;
    }

    const filterForAdvancedMode = () => {
        const filtered = [];
        props.payStations?.forEach( payStation => {
            const shouldDisplayForAccountsSelection = () => {
                const availableAccounts = buildAccountCandidateList();
                return props?.user?.userProperties?.filters[ currentFilter ]?.selection?.some( item => availableAccounts.includes( item ) );
            };
            const buildAccountCandidateList = () => {
                const reseller = extractReseller( payStation.box );
                const customer = extractCustomer( payStation.box );
                const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );
                const customerAccount = accounts.filter( item => item.code === customer.code && item.type === CUSTOMER );

                const availableAccounts = [];
                try {
                    if( resellerAccount[0] ) availableAccounts.push( `account-${resellerAccount[0].id}` );
                    if( customerAccount[0] ) availableAccounts.push( `account-${customerAccount[0].id}` );
                    let currentCandidate = ( resellerAccount[0] ) ? resellerAccount[0] : customerAccount[0];
                    while( currentCandidate.createdby && currentCandidate.createdby > 0 ) {
                        if( ! availableAccounts.includes( `account-${currentCandidate.createdby}` ) ) {
                            availableAccounts.push( `account-${currentCandidate.createdby}` );
                        }
                        const parents = accounts.filter( item => item.id === currentCandidate.createdby );
                        currentCandidate = parents[0];
                    }
                } catch ( error ) {
                    console.warn( error );
                }
                return availableAccounts;
            };

            if( props?.user?.userProperties?.filters[ currentFilter ]?.selection?.some( item => item === `box-${payStation.box?.id}` ) &&
                ! filtered.some( item => item === payStation ) ) {
                filtered.push( payStation );
            }

            if( shouldDisplayForAccountsSelection() &&
                ! filtered.some( item => item === payStation ) ) {
                filtered.push( payStation );
            }
        } );
        console.log( 'filtered' , filtered );
        return filtered;
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        if( props.onSearchMenuOpenedStateChanged ) {
            props.onSearchMenuOpenedStateChanged( event.currentTarget );
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentSearch(null);
        //document.getElementById("search-field").value = "";
        if( props.onSearchMenuOpenedStateChanged ) {
            props.onSearchMenuOpenedStateChanged( null );
        }
    };

    const handleModeSelection = () => {
        setIsSimpleMode( ! isSimpleMode );
        setIsEditing( false );
        shouldNotify = true;
    }

    const handleListSelection = ( evt , item ) => {
        if( props.onSelectionChanged ) {
            props.onSelectionChanged(evt, item);
        }

        if( props.variant ) {
            if( props.variant === "select" ) {
                handleClose();
            }
        }
    }

    const handleSearchChange = event => {
        const value = event.target.value;
        setCurrentSearch( value );
    }

    const handleNewFilter = () => {
        isNewFilter = true;
        loadAccounts();
        setIsEditing( true );
    }

    const handleEditFilter = event => {
        if( currentFilter !== '-1' ) {
            loadAccounts();
            const filterIndex = parseInt( props?.user?.userProperties?.properties?.currentFilter , 10 );
            editingFilterName = props?.user?.userProperties?.filters[ filterIndex ].name;
            setIsEditing( true );
        }
    }

    const handleRemoveFilter = event => {
        const filterIndex = parseInt( props?.user?.userProperties?.properties?.currentFilter , 10 );
        setPendingDelete(props?.user?.userProperties?.filters[ filterIndex ].name );
    }

    const handleConfirm = ( mode , target ) => {
        if( mode === "delete" ) {
            props?.user?.userProperties?.updateProperty( 'currentFilter' , '-1' );
            setCurrentFilter( "-1" );
            removeFilter( target );
            setPendingDelete(null);
        }
    }

    const handleEditApply = () => {
        const name = document.querySelector(`#name-field`);
        if( name && name.value && name.value.trim() !== '' ) {
            const filterName = name.value.trim();
            const filterIndex = saveFilter( filterName );
            if( filterIndex >= 0 ) {
                selectFilter( `${filterIndex}` );
            }
            setIsEditing( false );
            setSelectedItems( [] );
            setExpandedItems( [] );
            editingFilterName = null;
            isNewFilter = false;
        }
    }

    const handleEditCancel = () => {
        setIsEditing( false );
        setSelectedItems( [] );
        setExpandedItems( [] );
    }

    const saveFilter = filterName => {
        shouldNotify = true;
        const hasFilter = props?.user?.userProperties?.filters?.some( item => item.name === filterName );
        if( isNewFilter ) {
            if( ! hasFilter ) {
                const newFilters = JSON.parse( JSON.stringify( props?.user?.userProperties?.filters ) );
                const filter = {
                    name: filterName,
                    selection: selectedItems,
                    expanded: expandedItems
                };
                newFilters.push( filter );
                props?.user?.userProperties?.updateFilters( newFilters );
                props?.user?.observer?.handleSnackbar( "success" , I18n.get("Filter added") );
                return newFilters.indexOf( filter );
            } else {
                props?.user?.observer?.handleSnackbar( "error" , I18n.get("Filter already exists") );
                return -1;
            }
        } else {
            let index = -1;
            props?.user?.userProperties?.filters?.forEach( candidate => {
                if( candidate.name === filterName ) {
                    candidate.selection = selectedItems;
                    index = props?.user?.userProperties?.filters?.indexOf( candidate );
                }
            } );
            return index;
        }
    }

    const selectFilter = indexAsString => {
        setCurrentFilter( indexAsString );
        const filterIndex = parseInt( indexAsString , 10 );
        if( filterIndex >= 0 ) {
            setSelectedItems( props?.user?.userProperties?.filters[ filterIndex ].selection );
            setExpandedItems( props?.user?.userProperties?.filters[ filterIndex ].expanded );
        }
        props?.user?.userProperties?.updateProperty( 'currentFilter' , filterIndex );
        shouldNotify = true;
    }

    const selectReseller = indexAsString => {
        setReseller( indexAsString );
        setCustomer( '-1' );
    }

    const selectCustomer = indexAsString => {
        setCustomer( indexAsString );
    }

    const removeFilter = filterName => {
        props?.user?.userProperties?.updateFilters( props?.user?.userProperties?.filters?.filter( item => item.name !== filterName ) );
    }

    const handleSelectedItemsChange = ( event, ids ) => {
        setSelectedItems( ids );
    };

    const handleExpandedItemsChange = ( event, ids ) => {
        setExpandedItems( ids );
    };

    const handleSelectPayStation = ( id , name , boxExtraData ) => {
        const copy = JSON.parse( JSON.stringify( selectedDynamicItems ) );
        const index = copy.findIndex( candidate => candidate.id === id );
        if (index !== -1) {
            // Item exists in the list, remove it
            copy.splice(index, 1);
        } else {
            // Item doesn't exist in the list, add it
            copy.push( { id: id , name: name , boxExtraData: boxExtraData } );
        }
        setSelectedDynamicItems( copy );
        shouldNotify = true;
    }

    const clearPayStationSelection = () => {
        setSelectedDynamicItems( [] );
        shouldNotify = true;
        isClearRequested = true;
    }

    const selectAllPayStations = React.useCallback(() => {
        const defaultSelection = [];
        const resellers = {};
        const customers = {};

        if( ! accounts || ! props.payStations || ! selectedDynamicItems ) {
            loadAccounts();
            return null;
        }

        accounts.forEach( account => {
            if( account.type === RESELLER ) {
                resellers[account.id] = account.code;
            }

            if( account.type === CUSTOMER ) {
                if( reseller === `${account.createdby}` || reseller === '-1') {
                    customers[account.id] = account.code;
                }
            }
        } );

        const finder = new PayStationFinder( props.payStations );
        props.payStations?.forEach( payStation => {
            const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
            const boxExtraData = extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
            if( reseller && parseInt( reseller , 10 ) > -1 ) {
                if( customer && parseInt( customer , 10 ) > -1 ) {
                    if( payStation?.box?.informations?.sender?.reseller_id === resellers[reseller] &&
                        payStation?.box?.informations?.sender?.customer_code === customers[customer] ) {
                        defaultSelection.push( { id: payStation.box?.id , name: payStation?.box?.informations?.attributes?.name , boxExtraData: boxExtraData } );
                    }
                } else {
                    if( payStation?.box?.informations?.sender?.reseller_id === resellers[reseller] ) {
                        defaultSelection.push( { id: payStation.box?.id , name: payStation?.box?.informations?.attributes?.name , boxExtraData: boxExtraData } );
                    }
                }
            } else {
                if( customer && parseInt( customer , 10 ) > -1 ) {
                    if( payStation?.box?.informations?.sender?.customer_code === customers[customer] ) {
                        defaultSelection.push( { id: payStation.box?.id , name: payStation?.box?.informations?.attributes?.name , boxExtraData: boxExtraData } );
                    }
                } else {
                    defaultSelection.push( { id: payStation.box?.id , name: payStation?.box?.informations?.attributes?.name , boxExtraData: boxExtraData } );
                }
            }
        } )
        setSelectedDynamicItems( defaultSelection );
        shouldNotify = true;
    })

    const isSubmitEnabled = () => {
        const name = document.querySelector(`#name-field`);
        if( name && name.value && name.value.trim() !== '' ) {
            return selectedItems.length > 0;
        }
        return false;
    }

    const findAvailableFilters = () => {
        const availableFilters = [];
        props?.user?.userProperties?.filters?.forEach( filter => {
            let value = `${props?.user?.userProperties?.filters?.indexOf( filter )}`;
            if( value === '' ) {
                value = selectedItems.length - 1;
            }
            availableFilters.push( <MenuItem key={factoryUUID.generate()} value={value}>{filter.name}</MenuItem> );
        } );

        return availableFilters;
    };

    const loadAccounts = () => {
        if( props.API && props.user?.observer?.currentUser?.accountId ) {
            props.API
                .graphql({ query: getDetailedAccounts, variables: { accountId:props.user?.observer?.currentUser?.accountId }})
                .then( returned => {
                    const tool = new GraphQlTool( 'getDetailedAccounts' , returned );
                    setAccounts( tool.extract() );
                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    const extractReseller = box => {
        if( box && box.informations && box.informations.sender && box.informations.sender["reseller_id"] ) {
            return { code: box.informations.sender["reseller_id"] };
        }
        return { code: "Error" };
    };

    const extractCustomer = box => {
        if( box && box.informations && box.informations.sender && box.informations.sender["customer_code"] ) {
            return { code: box.informations.sender["customer_code"] };
        }
        return { code: "Error" };
    };

    const extractBoxName = box => {
        if( box && box.informations && box.informations.attributes && box.informations.attributes.name ) {
            return box.informations.attributes.name;
        }

        if( box && box.informations && box.informations.identity &&
            box.informations.identity.model && box.informations.identity.serial ) {
            return `${box.informations.identity.model} ${box.informations.identity.serial}`;
        }

        return I18n.get( "Unknown" );
    };

    const buildTree = () => {
        const tree = [];
        const rootLevel = {
            id: `account-${props.user?.observer?.currentUser?.accountId}`,
            label: `${props.user?.observer?.currentUser?.accountLabel}`,
            children: []
        };
        const quickPerfAccess = {};
        quickPerfAccess[`account-${props.user?.observer?.currentUser?.accountId}`] = rootLevel;

        const addIfNotExists = account => {
            if( account && ! quickPerfAccess.hasOwnProperty( `account-${account.id}` ) ) {
                const treeItems = {
                    id: `account-${account.id}`,
                    label: `${account.name}`,
                    children: []
                }
                if( account.createdby ) {
                    quickPerfAccess[`account-${account.createdby}`]?.children.push( treeItems );
                    quickPerfAccess[`account-${account.id}`] = treeItems;
                }
            }
        }

        if( props.payStations && accounts ) {
            const activePayStations = configAnalyzer.getDistinctActiveBoxCBMSList();
            props.payStations.forEach( payStation => {
                if( activePayStations.includes( `4_${payStation.box.informations?.bms}` ) ) {
                    const reseller = extractReseller( payStation.box );
                    const customer = extractCustomer( payStation.box );
                    const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );
                    const customerAccount = accounts.filter( item => item.code === customer.code && item.type === CUSTOMER );

                    try {
                        addIfNotExists( resellerAccount[0] );
                        addIfNotExists( customerAccount[0] );
                        if( ! quickPerfAccess.hasOwnProperty( `box-${payStation?.box?.id}` ) ) {
                            const treeItems = {
                                id: `box-${payStation?.box?.id}`,
                                label: `${ ( payStation?.box?.informations?.attributes?.name ) ? payStation?.box?.informations?.attributes?.name : payStation?.box?.name }`,
                                children: []
                            }
                            if( customerAccount && customerAccount.length > 0 ) {
                                quickPerfAccess[`account-${customerAccount[0].id}`]?.children.push( treeItems );
                            } else if ( resellerAccount && resellerAccount.length > 0 ) {
                                quickPerfAccess[`account-${resellerAccount[0].id}`]?.children.push( treeItems );
                            } else {
                                console.log( 'CAN NOT HAPPEN IF SO YOU SUCKS DEV !!!!' );
                            }
                            quickPerfAccess[`box-${payStation?.box?.id}`] = treeItems;

                            payStation.devices.forEach( device => {
                                const type = device.informations?.identity?.model;
                                if( ! quickPerfAccess.hasOwnProperty( `device-${type}-${device?.id}` ) ) {
                                    treeItems.children.push( {
                                        id: `device-${type}-${device?.id}`,
                                        label: `${device?.name}`,
                                        children: []
                                    } );
                                    quickPerfAccess[`device-${type}-${device?.id}`] = `${device?.name}`;
                                }
                            } );
                        }
                    } catch ( error ) {

                    }
                }
            } );
        }
        tree.push( rootLevel );
        return tree;
    }

    const renderTriggerElement = () => {
        if( props.variant ) {
            //manage variant display
            if( props.variant === "select" ) {
                let properties = {
                    id:"node",
                    allowEmpty:false,
                    label:I18n.get("Select node"),
                    defaultValue:"",
                    onValueChanged:null,
                    isHashMapOption:false,
                    options:[""]
                }

                if( props.SelectProps ) {
                    properties = props.SelectProps;
                }

                return (
                    <SelectHidingMenu 	  id={properties.id}
                                          label={properties.label}
                                          allowEmpty={properties.allowEmpty}
                                          defaultValue={properties.defaultValue}
                                          onValueChanged={properties.onValueChanged}
                                          isDarkStyle={props.isDarkStyle}
                                          isHashMapOption={properties.isHashMapOption}
                                          handleClick={handleClick}
                                          disabled={properties.disabled}
                                          options={properties.options}  />
                );
            }

        }
        //default display return a button with a search icon !!!
        const filterIndex = parseInt( props?.user?.userProperties?.properties?.currentFilter , 10 );
        const reminder = ( currentFilter !== '-1' ) ?  props?.user?.userProperties?.filters[ filterIndex ].name  : I18n.get('No filter selected');
        return (
            <div className={`reminder-filter`}>
                <div className={`filter-name`}>{(isSimpleMode) ? '' : reminder}</div>
                <IconButton key="search-icon Icon"
                            className="search-icon Icon"
                            disableRipple
                            onClick={handleClick} >
                    {/*<FontAwesomeIcon icon="fa-brands fa-searchengin" />*/}
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                </IconButton>
            </div>
        );
    }

    const renderSearchContent = () => {
        const key = ( isSimpleMode ) ? 'Simple filter management' : 'Advanced filter management';
        return (
            <div className="dropdown-menu-md overflow-hidden p-3 cm-search-menu">
                <div className="grid-menu grid-menu-1col">
                    <div className="title">
                        <span>{I18n.get( key )}</span>
                        {renderModeSelector()}
                    </div>
                    {renderSelectedFilter()}
                    {renderFilterEditingMode()}
                </div>
            </div>
        );
    }

    const renderModeSelector = () => {
        //tmp
        return null;
        //Advanced mode must be reviewed
        return (
            <div className="mode-toggle button" onClick={handleModeSelection}>
                {(!isSimpleMode) ? I18n.get('Simple mode') : I18n.get('Advanced mode')}
            </div>
        );
    }

    const renderPopover = () => {
        return (
            <Popover anchorEl={anchorEl}
                     key={`search-engine`}
                     keepMounted
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}
                     open={Boolean(anchorEl)}
                     classes={{ paper: cssMenu }}
                     onClose={handleClose} >
                {renderSearchContent ()}
            </Popover>
        );
    }

    const renderSelectedFilter = () => {
        if( isSimpleMode ) {
            return renderSimpleMode();
        } else {
            const availableFilters = findAvailableFilters();
            return (
                <div className={`filter-row`}>
                    <Select
                        className={`filter-selector`}
                        value={currentFilter}
                        onChange={ event => selectFilter( event.target.value )}
                    >
                        <MenuItem key={factoryUUID.generate()} value="-1">{I18n.get('No filter selected')}</MenuItem>
                        {availableFilters.map( filter  => filter )}
                    </Select>
                    <div key={factoryUUID.generate()} className={`button`}  onClick={handleNewFilter}>
                        { I18n.get("Create filter") }
                    </div>
                    <div key={factoryUUID.generate()} className={`button ${( currentFilter === '-1' ) ? 'disabled' : ''}`}  onClick={handleEditFilter}>
                        { I18n.get("Edit filter") }
                    </div>
                    <div key={factoryUUID.generate()} className={`button ${( currentFilter === '-1' ) ? 'disabled' : ''}`}  onClick={handleRemoveFilter}>
                        { I18n.get("Remove filter") }
                    </div>
                </div>
            );
        }
    };

    const renderFilterEditingMode = () => {
        if( ! isEditing ) {
            return null;
        }
        const tree = buildTree();
        const treeItems = [];
        tree.forEach( item => {
            treeItems.push( renderTreeItem( item ) );
        } );
        return (
            <div className={`filter-editing-mode`}>
                <Box sx={{ minHeight: 200, flexGrow: 1, maxHeight: 500, overflowY: 'auto' }}>
                    <div className={`filter-header`}>
                        <div className="name">
                            <TextField
                                id="name-field"
                                label={I18n.get("Filter name")}
                                defaultValue={(currentFilter !== '-1' && !isNewFilter ) ? editingFilterName : ''}
                                value={filterName}
                                variant="outlined"
                                /*autoComplete="off"*/
                                disabled={(currentFilter !== '-1' && !isNewFilter)}
                                onBlur={(evt) => setFilterName( evt.target.value ) }
                            />
                        </div>
                        <div className={`filter-selected-count`}>
                            {selectedItems.length}
                            <FontAwesomeIcon icon="fa-solid fa-clipboard" />
                        </div>
                    </div>
                    <SimpleTreeView multiSelect
                                    selectedItems={selectedItems}
                                    expandedItems={expandedItems}
                                    onExpandedItemsChange={handleExpandedItemsChange}
                                    onSelectedItemsChange={handleSelectedItemsChange}>
                        {treeItems.map( item => item )}
                    </SimpleTreeView>
                </Box>
                <div key={factoryUUID.generate()} className={`user-actions`}>
                    <div key={factoryUUID.generate()} className={`button ${( isSubmitEnabled() ) ? '' : 'disabled'}`}  onClick={handleEditApply}>
                        { I18n.get("Apply") }
                    </div>
                    <div key={factoryUUID.generate()} className={`button`}  onClick={handleEditCancel}>
                        { I18n.get("Cancel") }
                    </div>
                </div>
            </div>

        );
    }

    const renderSimpleMode = () => {
        const resellers = [];
        const customers = [];
        const identification = {
            resellerId: null,
            customerCode: null
        };

        if( ! accounts /*|| ! props.payStations*/ || ! selectedDynamicItems ) {
            loadAccounts();
            return null;
        }

        accounts.forEach( account => {
            if( account.type === RESELLER ) {
                resellers.push( <MenuItem key={factoryUUID.generate()} value={`${account.id}`}>{account.name}</MenuItem> );
                if( reseller === `${account.id}`) {
                    identification.resellerId = account.code;
                }
            }

            if( account.type === CUSTOMER ) {
                if( reseller === `${account.createdby}` || reseller === '-1') {
                    customers.push( <MenuItem key={factoryUUID.generate()} value={`${account.id}`}>{account.name}</MenuItem> );
                    if( customer === `${account.id}` ) {
                        identification.customerCode = account.code;
                    }
                }
            }
        } );

        const finder = new PayStationFinder( props.payStations );
        const payStationsForSelection = [];
        props.payStations?.forEach( payStation => {
            if( ( payStation?.box?.informations?.sender?.reseller_id === identification.resellerId || reseller === '-1' ) &&
                ( payStation?.box?.informations?.sender?.customer_code === identification.customerCode || customer === '-1' ) ) {
                const extraCss = ( selectedDynamicItems.findIndex( candidate => candidate.id === payStation?.box?.id ) !== -1 ) ? 'selected' : '';
                const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
                const boxExtraData = extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
                payStationsForSelection.push(   <div key={factoryUUID.generate()}
                                                   className={`clickable line-payStation ${extraCss}`}
                                                   onClick={() => {
                                                       handleSelectPayStation( payStation?.box?.id , payStation?.box?.informations?.attributes?.name , boxExtraData );
                                                   }}>
                                                    <div className={`name`}>{payStation?.box?.informations?.attributes?.name}</div>
                                                    <div className={`extra-info`}>{boxExtraData}</div>
                                                </div> );
            }
        } );

        const selectedPayStations = [];
        selectedDynamicItems.forEach( item => {
            selectedPayStations.push( <div key={factoryUUID.generate()} className={`box-display`}> <div>{item.name}</div> <div className={`displayed extra-info`}> ( {(item.boxExtraData)?item.boxExtraData:''} ) </div></div> );
        } );

        return (
            <div key={factoryUUID.generate()} className={`simple-mode-filters`}>
                <div key={factoryUUID.generate()} className={`selectors`}>
                    <div key={factoryUUID.generate()} className={`combo-container`}>
                        <div key={factoryUUID.generate()} className={`combo-block`}>
                            <Select
                                className={`filter-selector`}
                                value={reseller}
                                disabled={props.user.observer.currentUser.role === UserRoles.SALE_ROLE}
                                onChange={ event => selectReseller( event.target.value )}
                            >
                                <MenuItem key={factoryUUID.generate()} value="-1">{I18n.get('All')}</MenuItem>
                                {resellers.map( reseller  => reseller )}
                            </Select>
                            <div key={factoryUUID.generate()} className={`combo-label`}> {I18n.get('Reseller')} </div>
                        </div>
                        <div key={factoryUUID.generate()} className={`combo-block`}>
                            <Select
                                className={`filter-selector`}
                                value={customer}
                                disabled={props.user.observer.currentUser.role === UserRoles.SALE_ROLE}
                                onChange={ event => selectCustomer( event.target.value )}
                            >
                                <MenuItem key={factoryUUID.generate()} value="-1">{I18n.get('All')}</MenuItem>
                                {customers.map( customer  => customer )}
                            </Select>
                            <div key={factoryUUID.generate()} className={`combo-label`}> {I18n.get('Customer')} </div>
                        </div>
                    </div>
                    <div key={factoryUUID.generate()} className={`box-container`}>
                        <div key={factoryUUID.generate()} className={`section-title`}>{I18n.get('Available')}</div>
                        <div key={factoryUUID.generate()} className={`separator`} ></div>
                        <div key={factoryUUID.generate()} className={`content`} >
                            {payStationsForSelection.map( payStation => payStation )}
                        </div>
                    </div>
                </div>
                <div key={factoryUUID.generate()} className={`selection`}>
                    <div key={factoryUUID.generate()} className={`section-title`}>
                        <span>{I18n.get('Selected')}</span>
                        <FontAwesomeIcon icon="fa-solid fa-list-check"
                                         className={`quick-trash`}
                                         onClick={selectAllPayStations}/>
                        {/*<FontAwesomeIcon icon="fa-solid fa-check-double"
                                         className={`quick-trash`}
                                         onClick={selectAllPayStations}/>*/}
                        <FontAwesomeIcon icon="fa-solid fa-trash"
                                         className={`quick-trash`}
                                         onClick={clearPayStationSelection}/>
                    </div>
                    <div key={factoryUUID.generate()} className={`separator`} ></div>
                    <div key={factoryUUID.generate()} className={`content`} >
                        {selectedPayStations.map( payStation => payStation )}
                    </div>
                </div>
            </div>
        );
    }

    const StyledTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
        padding: theme.spacing(0.5, 1),
    }));

    const StyledTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
        const { id, itemId, label, disabled, children, ...other } = props;

        const {
            getRootProps,
            getContentProps,
            getIconContainerProps,
            getLabelProps,
            getGroupTransitionProps,
            status,
        } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

        const resolveIcon = idItem => {
            if( idItem.startsWith( 'account-' ) ) {
                const idAccount = parseInt( idItem.replace( 'account-' , '' ) , 10 );
                if( idAccount !== props.user?.observer?.currentUser?.accountId ) {
                    let type = null;
                    if( accounts ) {
                        accounts.forEach( candidate => {
                            if( candidate.id === idAccount ) {
                                type = candidate.type;
                            }
                        } );
                        if( type === RESELLER ) {
                            return getIconForNode( {role: roles.RESELLER} );
                        } else if ( type === CUSTOMER ) {
                            return getIconForNode( {role: roles.CUSTOMER} );
                        }
                    }
                }
            } else if ( idItem.startsWith( 'box-' ) ) {
                return getIconForNode( {role: roles.BOX} );
            } else if ( idItem.startsWith( 'device-' ) ) {
                if( idItem.includes( 'BNR' ) || idItem.includes( 'SCR' ) ) {
                    return fundType.getIcon( fundType.CASH_NOTE );
                } else {
                    return fundType.getIcon( fundType.CASH_COIN );
                }
            }
            return `fa-solid fa-house`;
        };
        const icon = resolveIcon( itemId );

        return (
            <TreeItem2Provider itemId={itemId}>
                <TreeItem2Root {...getRootProps(other)}>
                    <StyledTreeItemContent {...getContentProps()} className={`${(selectedItems.some( item => item === itemId )) ? 'selected item-content' : 'item-content'}`}>
                        <TreeItem2IconContainer {...getIconContainerProps()}>
                            <TreeItem2Icon status={status} />
                        </TreeItem2IconContainer>
                        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                            <FontAwesomeIcon icon={icon}/>
                            <TreeItem2Label {...getLabelProps()} />
                        </Box>
                    </StyledTreeItemContent>
                    {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
                </TreeItem2Root>
            </TreeItem2Provider>
        );
    });

    const renderTreeItem = element => {
        const children = [];
        element.children.forEach( child => {
            children.push( renderTreeItem( child ) );
        } );
        return (
            <StyledTreeItem key={factoryUUID.generate()}
                            itemId={element.id}
                            label={element.label}
                            disabled={(element.id.startsWith('device-')) }>
                {children.map( item => item )}
            </StyledTreeItem>
        );
        //using treeItem1 not icon available
        /*return (
            <TreeItem key={factoryUUID.generate()} itemId={element.id} label={element.label} className={`Mui-expanded`}>
                {children.map( item => item )}
            </TreeItem>
        );*/
    }

    const renderConfirmDialog = () => {
        return (
            <DialogConfirmRemove handleClose={handleConfirm}
                                 pendingDelete={pendingDelete}
                                 isDarkStyle={props.isDarkStyle}/>
        );
    }

    React.useEffect( () => {
        if( props?.user?.userProperties?.properties?.currentFilter &&
            props?.user?.userProperties?.properties?.currentFilter !== currentFilter ) {
            setCurrentFilter( props?.user?.userProperties?.properties?.currentFilter );
            if( props?.user?.userProperties?.filters[ filterIndex ] ) {
                setSelectedItems( props?.user?.userProperties?.filters[ filterIndex ].selection );
                setExpandedItems( props?.user?.userProperties?.filters[ filterIndex ].expanded );
            }
        }

        if( isSimpleMode && props.payStations && props.payStations.length > 0 && selectedDynamicItems.length === 0 && ! isClearRequested ) {
            selectAllPayStations();
        }

        if( ( parseInt( reseller , 10 ) > -1 && reseller !== previousReseller ) ||
            ( parseInt( customer , 10 ) > -1 && customer !== previousCustomer ) ) {
            previousReseller = reseller;
            previousCustomer = customer;
            clearPayStationSelection();
            selectAllPayStations();
        }
    } , [
        props.user?.userProperties?.properties?.currentFilter,
        props.user?.userProperties?.filters,
        props.payStations,
        currentFilter,
        isSimpleMode,
        selectedDynamicItems.length,
        filterIndex,
        selectAllPayStations,
        reseller,
        customer
    ] );

    let css = "search";
    let cssMenu = "p-0"
    if( props.isDarkStyle ) {
        css += " dark";
        cssMenu += " dark";
    }

    props.registerFilterDelegate( filter );

    if( shouldNotify && props.onFilterUpdated ) {
        shouldNotify = false;
        props.onFilterUpdated( filter() );
    }

    return (
        <React.Fragment>
            <div className={css}>
                {renderTriggerElement()}
                {renderPopover()}
                {renderConfirmDialog()}
            </div>
        </React.Fragment>
    );
};

export default UserFilteringEngine;
