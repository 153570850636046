import * as subscriptions from '../graphql/subscriptions';
import {
	getPayStationsForAccount,
	getLatestBoxInf,
	getLatestDeviceInf,
	getLatestTerminalInf,
	getLatestSoftwareInf,
	getLatestPlatformInf,
	getLatestTechnicalData,
	listOperations,
	listMessages,
	getLatestInventory,
	getUserGroups, getPreferences, getAccountOptions, getLatestState
} from "../graphql/queries";
import GraphQlTool from "./GraphQlTool";
import {UserRoles} from "../Models/Roles";
import UUID from "./UUID";
import {closeDevicePeriod} from "../graphql/mutations";

const factoryUUID = new UUID();

let instanceSyncDataManager;

class SyncDataManager {
	constructor( cognitoUser , observer ) {
		instanceSyncDataManager = this;
		this.user = cognitoUser;
		this.observer = observer;
		this.queue = [];
		this.subscriptions = [];
		this.notificationHistory = {};

		this.loadLicensesForAccount()
			.then( licenses => this.loadAllowedBoxes( licenses ) )
			.then( allowedBoxIds => this.loadPayStationsForAccount( allowedBoxIds ) )
			.then( payStations => this.formatPayStationList( payStations ) )
			.then( payStations => this.loadAllLatestInformations( payStations ) )
			.then( payStations => this.synchronizeLoading( payStations ) )
			.then( payStations => {
				if( instanceSyncDataManager.observer &&
					instanceSyncDataManager.observer.onDataLoaded ) {
					if( this.licenses > -1 && payStations.length > this.licenses ) {
						instanceSyncDataManager.observer.onLicenseRequired( payStations.length - this.licenses );
						payStations = payStations.slice( 0, this.licenses );
					}
					instanceSyncDataManager.observer.onDataLoaded( this.anonymizePayStationsIfRequired( payStations ) );
				}
		} );
	}

	loadAllowedBoxes( licenses ) {
		this.licenses = licenses;
		return new Promise( resolve => {
			if( instanceSyncDataManager.observer.props.API ) {
				instanceSyncDataManager.observer.props.API
					.graphql({ query: getUserGroups, variables: { cognitoId: this.user.uuid }})
					.then( returned => {
						if( returned ) {
							const tool = new GraphQlTool( 'getUserGroups' , returned );
							const allowedBoxIds = tool.extract();
							resolve( allowedBoxIds );
						} else {
							resolve( [] );
						}
					})
					.catch((error) => {
						console.error("error" , error);
					})
			} else {
				resolve();
			}
		} );
	}

	loadLicensesForAccount() {
		return new Promise( resolve => {
			if( instanceSyncDataManager.observer.props.API ) {
				instanceSyncDataManager.observer.props.API
					.graphql({
						query: getAccountOptions,
						variables: { accountId: this.user.accountId }
					})
					.then( returned => {
						if( returned ) {
							let licenses = 0;
							if( returned && returned.data && returned.data.getAccountOptions ) {
								try {
									const params = returned.data.getAccountOptions;
									licenses = JSON.parse( params.options ).licenses;
								} catch ( error ) {
									//unused
								}
							}
							resolve( licenses );
						}
					})
					.catch((error) => {
						console.error("error" , error);
					})
			} else {
				resolve();
			}
		} );
	}

	filterPayStationOnUserRestriction( allowedBoxIds , accountPayStations ) {
		const filtered = [];
		accountPayStations.forEach( candidate => {
			if( allowedBoxIds.includes( candidate['box_id'] ) ) {
				filtered.push( candidate );
			}
		} );
		return filtered;
	}

	filterDistinctBoxes( payStations ) {
		const filtered = [];
		payStations?.forEach( candidate => {
			const existsInFiltered = filtered.some( item => item['box_id'] === candidate['box_id'] );
			if( ! existsInFiltered ) {
				filtered.push( candidate );
			}
		} );
		return filtered;
	}

	anonymizePayStationsIfRequired( payStations ) {
		const anonymized = {};
		if( this.user.role === UserRoles.SALE_ROLE ) {
			payStations.forEach( payStation => {
				payStation.box.name = `Monnayeur ${payStations.indexOf( payStation ) + 1}`;
				payStation.box.informations.attributes.name = `Monnayeur ${payStations.indexOf( payStation ) + 1}`;

				payStation.terminals.forEach( terminal => {
					terminal.name = `Terminal ${payStation.terminals.indexOf( terminal ) + 1}`;
					terminal.informations.attributes.terminal = `Terminal ${payStation.terminals.indexOf( terminal ) + 1}`;
					terminal.informations.attributes.company = `Société ${payStations.indexOf( payStation ) + 1}`;
					terminal.informations.attributes.shop = `Magasin ${payStations.indexOf( payStation ) + 1}`;

					terminal.operations.forEach( operation => {
						if( ! anonymized[operation.cashier] ) {
							anonymized[operation.cashier] = `Caissier ${Object.keys( anonymized ).length + 1}`;
						}
						operation.cashier = anonymized[operation.cashier];
					} );
				} );
			} );
		}

		return payStations;
	}

	loadPayStationsForAccount( allowedBoxIds) {
		return new Promise(resolve => {
			this.loadPayStationsBulk( resolve , allowedBoxIds, 0, [] );
		});
	}

	loadPayStationsBulk ( resolve , allowedBoxIds, offset = 0, accumulatedPayStations = [] ) {
		if (instanceSyncDataManager.observer.props.API) {
			instanceSyncDataManager.observer.props.API
				.graphql({
					query: getPayStationsForAccount,
					variables: {
						accountId: this.user.accountId,
						cognitoId: this.user.uuid,
						offset: offset
					}
				})
				.then(returned => {
					const tool = new GraphQlTool('getPayStationsForAccount', returned);
					const paginatedPayStations = tool.extract();
					const payStations = paginatedPayStations.records;
					accumulatedPayStations = accumulatedPayStations.concat( payStations );
					if ( accumulatedPayStations.length < paginatedPayStations['records_count']) {
						instanceSyncDataManager.loadPayStationsBulk( resolve , allowedBoxIds, accumulatedPayStations.length , accumulatedPayStations )
					} else {
						if (allowedBoxIds && allowedBoxIds.length > 0) {
							resolve( instanceSyncDataManager.filterPayStationOnUserRestriction( allowedBoxIds, accumulatedPayStations ) );
						} else {
							resolve( accumulatedPayStations );
						}
					}
				})
				.catch(error => {
					resolve( error );
				});
		}
	}

	formatPayStationList( payStations ) {
		return new Promise( resolve => {
			if( payStations && payStations.length > 0 ) {
				const formatted = [];
				payStations.forEach( payStation => {
					let foundPayStation = this.findPayStationIfExists( payStation["box_id"] , formatted );
					if( ! foundPayStation ) {
						foundPayStation = {
							box: {
								id: payStation["box_id"],
								name: payStation["box_name"],
								active: payStation["box_active"],
								creation: payStation["box_creation"],
								lastUpdate: payStation["box_last_update"]
							},
							devices: [],
							terminals: []
						};
						formatted.push( foundPayStation );
					}

					this.addDeviceIfRequired( foundPayStation , payStation );
					this.addTerminalIfRequired( foundPayStation , payStation );

					if( payStations.indexOf( payStation ) === payStations.length - 1 ) {
						resolve( formatted );
					}
				} );
			} else {
				resolve([] );
			}
		} );
	}

	loadAllLatestInformations( payStations ) {
		return new Promise( resolve => {
			if( payStations && payStations.length > 0 ) {
				const done = [];
				payStations.forEach( payStation => {
					instanceSyncDataManager
						.loadLatestInformationsForOnePayStation( payStation )
						.then( () => {
							done.push( payStation );
							if( done.length === payStations.length ) {
								resolve( payStations );
							}
						} );
				} );
			} else {
				resolve([] );
			}
		} );
	}

	loadLatestInformationsForOnePayStation( payStation ) {
		return new Promise( resolve => {
			if( payStation ) {
				instanceSyncDataManager
					.loadLatestBoxInformations( payStation )
					.then( () => instanceSyncDataManager.loadLatestDevicesInformations( payStation.devices , payStation ) )
					.then( () => instanceSyncDataManager.loadLatestTerminalsInformations( payStation.terminals ) )
					.then( () => instanceSyncDataManager.loadDailyOperations( payStation.terminals ) )
					.then( () => instanceSyncDataManager.loadDailyMessages( payStation.devices ) )
					.then( () => instanceSyncDataManager.loadLatestBoxSoftwareInformations( payStation ) )
					.then( () => instanceSyncDataManager.loadLatestBoxPlatformInformations( payStation ) )
					.then( () => instanceSyncDataManager.loadLatestBoxInventory( payStation ) )
					.then( () => instanceSyncDataManager.loadBoxPreferencesForUser( payStation ) )
					.then( () => {
						resolve( payStation );
					} );
			} else {
				resolve({} );
			}
		} );
	}

	loadLatestBoxInformations( payStation ) {
		return new Promise( resolve => {
			if( payStation.box.name && payStation.box.name.trim() !== "" && payStation.box.lastUpdate ) {
				if( instanceSyncDataManager.observer.props.API ) {
					instanceSyncDataManager.observer.props.API
						.graphql({ query: getLatestBoxInf, variables: { input: { bms: payStation.box.name, time:payStation.box.lastUpdate } } })
						.then( returned => {
							if( returned && returned.data && returned.data.getLatestBoxInf ) {
								payStation.box.informations = returned.data.getLatestBoxInf;
							}
							resolve();
						})
						.catch((error) => {
							console.error("error" , error);
							resolve();
						})
				} else {
					resolve();
				}
			} else {
				resolve();
			}
		} );
	}

	loadBoxPreferencesForUser( payStation ) {
		return new Promise( resolve => {
			if( payStation.box.id && instanceSyncDataManager.user.uuid ) {
				if( instanceSyncDataManager.observer.props.API ) {
					instanceSyncDataManager.observer.props.API
						.graphql({ query: getPreferences, variables: { userIdAndBoxId: `${instanceSyncDataManager.user.uuid}@${payStation.box.id}` } })
						.then( returned => {
							if( returned && returned.data && returned.data.getPreferences ) {
								const preferences = JSON.parse( returned.data.getPreferences.data );
								if( preferences ) {
									if( payStation.box?.informations?.attributes?.name ) {
										payStation.box.informations.attributes.name = preferences.box;
									} else {
										payStation.box.name = preferences.box;
									}

									if( payStation.terminals && preferences.terminals ) {
										payStation.terminals?.forEach( terminal => {
											const terminalItem = preferences.terminals[payStation.terminals.indexOf(terminal)];
											if( terminal.informations?.attributes?.terminal ) {
												terminal.informations.attributes.terminal = terminalItem?.name;
												terminal.informations.attributes.company = preferences.company;
												terminal.informations.attributes.shop = preferences.shop;
											} else {
												terminal.name = terminalItem?.name;
											}
										} );
									}
								}
							}
							resolve();
						})
						.catch((error) => {
							console.error("error" , error);
							resolve();
						})
				} else {
					resolve();
				}
			} else {
				resolve();
			}
		} );
	}

	loadLatestBoxSoftwareInformations( payStation ) {
		return new Promise( resolve => {
			if( payStation.box.informations && payStation.box.informations.attributes && payStation.box.informations.attributes.software ) {
				if( instanceSyncDataManager.observer.props.API ) {
					instanceSyncDataManager.observer.props.API
						.graphql({ query: getLatestSoftwareInf, variables: {
							input: {
								bms: payStation.box.informations.attributes.software.substring( 2 ),
								time:payStation.box.lastUpdate }
						} })
						.then( returned => {
							if( returned && returned.data && returned.data.getLatestSoftwareInf ) {
								payStation.box.software = returned.data.getLatestSoftwareInf;
							}
							resolve();
						})
						.catch((error) => {
							console.error("error" , error);
							resolve();
						})
				} else {
					resolve();
				}
			} else {
				resolve();
			}
		} );
	}

	loadLatestBoxPlatformInformations( payStation ) {
		return new Promise( resolve => {
			if( payStation.box.informations && payStation.box.informations.attributes && payStation.box.informations.attributes.platform ) {
				if( instanceSyncDataManager.observer.props.API ) {
					instanceSyncDataManager.observer.props.API
						.graphql({ query: getLatestPlatformInf, variables: {
								input: {
									bms: payStation.box.informations.attributes.platform.substring( 2 ),
									time:payStation.box.lastUpdate }
							} })
						.then( returned => {
							if( returned && returned.data && returned.data.getLatestPlatformInf ) {
								payStation.box.platform = returned.data.getLatestPlatformInf;
							}
							resolve();
						})
						.catch((error) => {
							console.error("error" , error);
							resolve();
						})
				} else {
					resolve();
				}
			} else {
				resolve();
			}
		} );
	}

	loadLatestBoxInventory( payStation ) {
		return new Promise( resolve => {
			if( payStation.devices && payStation.devices.length > 0 ) {
				const loaded = [];
				payStation.devices.forEach( device => {
					if( instanceSyncDataManager.observer.props.API && device.informations ) {
						instanceSyncDataManager.observer.props.API
							.graphql({ query: getLatestInventory, variables: {
										cbms: `5_${device.informations.bms}`,
										requestDate:new Date().toISOString()
								} })
							.then( returned => {
								if( returned && returned.data && returned.data.getLatestInventory ) {
									device.latestInventory = returned.data.getLatestInventory;
								}
								loaded.push( device.informations.bms );
								if( loaded.length === payStation.devices.length ) {
									resolve();
								}
							})
							.catch((error) => {
								console.error("error" , error);
								loaded.push( device.informations.bms );
								if( loaded.length === payStation.devices.length ) {
									resolve();
								}
							})
					} else {
						resolve();
					}
				} )
			} else {
				resolve();
			}
		} );
	}

	loadLatestDevicesInformations( devices , payStation ) {
		return new Promise( resolve => {
			if( devices && devices.length > 0 ) {
				const done = [];
				devices.forEach( device => {
					const latestUse = instanceSyncDataManager.getLatestUse( device.periods );
					if( device.name && latestUse ) {
						if( instanceSyncDataManager.observer.props.API ) {
							instanceSyncDataManager.observer.props.API
								.graphql({ query: getLatestDeviceInf, variables: { input: { bms: device.name, time:latestUse } } })
								.then( returned => {
									if( returned && returned.data && returned.data.getLatestDeviceInf ) {
										device.informations = returned.data.getLatestDeviceInf;
									}
									done.push( device );
									if( done.length === devices.length ) {
										resolve();
									}
								})
								.catch((error) => {
									console.error("error" , error);
									done.push( device );
									if( done.length === devices.length ) {
										resolve();
									}
								})
							instanceSyncDataManager.loadLatestStateForDevice( device , latestUse );

							if( ! payStation?.box?.informations?.attributes?.devices?.includes( `5_${device.name}` ) ) {
								console.log( 'Must close period for ' , device );
								this.closePeriodForInvalidDevice( device.id );
							}

						} else {
							resolve();
						}
					} else {
						resolve();
					}
				} );
			} else {
				resolve();
			}
		} );
	}

	loadLatestStateForDevice( device , latestUse ) {
		if( device.name && latestUse ) {
			if( instanceSyncDataManager.observer.props.API ) {
				instanceSyncDataManager.observer.props.API
					.graphql({ query: getLatestState, variables: { cbms: `5_${device.name}`, at:latestUse } })
					.then( returned => {
						if( returned && returned.data && returned.data.getLatestState ) {
							device.lastState = returned.data.getLatestState;
						}
					})
					.catch((error) => {
						console.error("error" , error);
					})
			}
		}
	}

	loadLatestTerminalsInformations( terminals , show ) {
		return new Promise( resolve => {
			if( terminals && terminals.length > 0 ) {
				const done = [];
				terminals.forEach( terminal => {
					const latestUse = instanceSyncDataManager.getLatestUse( terminal.periods );
					if( terminal.name && latestUse ) {
						if( instanceSyncDataManager.observer.props.API ) {
							instanceSyncDataManager.observer.props.API
								.graphql({ query: getLatestTerminalInf, variables: { input: { bms: terminal.name, time:latestUse } } })
								.then( returned => {
									if( returned && returned.data && returned.data.getLatestTerminalInf ) {
										terminal.informations = returned.data.getLatestTerminalInf;
										instanceSyncDataManager.observer.props.API
											.graphql({ query: getLatestSoftwareInf, variables: {
													input: {
														bms: terminal.informations.attributes.software.substring( 2 ),
														time: new Date().toISOString() }
												} })
											.then( returned2 => {
												terminal.software = returned2.data.getLatestSoftwareInf;
											})
											.catch((error) => {
												console.error("error" , error);
											})
									}
									done.push( terminal );
									if( done.length === terminals.length ) {
										resolve();
									}
								})
								.catch((error) => {
									console.error("error" , error);
									done.push( terminal );
									if( done.length === terminals.length ) {
										resolve();
									}
								})
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				} );
			} else {
				resolve();
			}
		} );
	}

	loadDailyOperations( terminals , show ) {
		return new Promise( resolve => {
			if( terminals && terminals.length > 0 ) {
				const done = [];
				terminals.forEach( terminal => {
					terminal.operations = [];
					if( terminal.informations ) {
						this.currentCbms = terminal.informations.bms;
						const start = new Date();
						start.setHours( 0 , 0 , 0 , 0 );
						const end = new Date();
						end.setHours( 23 , 59 , 59 , 999 );
						this.loadOperations( terminal , `6_${terminal.informations.bms}` , {
							start: start.toISOString(),
							end: end.toISOString()
						} , null );
					}
					resolve();
				} );
			} else {
				resolve();
			}
		} );
	}

	loadOperations( terminal , cbms , period , nextToken ) {
		if( ! instanceSyncDataManager.queue.includes( cbms ) ) {
			instanceSyncDataManager.queue.push( cbms );
		}

		if( instanceSyncDataManager.observer.props.API ) {
			instanceSyncDataManager.observer.props.API
				.graphql({ query: listOperations, variables: { input: { cbms: cbms , count: 10 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listOperations ) {
						returned.data.listOperations.operations.forEach( operation => {
							terminal.operations.push( operation );
						} );
						if( returned.data.listOperations.nextToken ) {
							this.loadOperations( terminal , cbms , period , returned.data.listOperations.nextToken );
						} else {
							if( instanceSyncDataManager.queue.includes( cbms ) ) {
								instanceSyncDataManager.queue.splice( instanceSyncDataManager.queue.indexOf( cbms ) , 1 );
							}
						}
					}
				})
				.catch((error) => {

				})
		}
	}

	loadDailyMessages( devices , show ) {
		return new Promise( resolve => {
			if( devices && devices.length > 0 ) {
				const done = [];
				devices.forEach( device => {
					device.messages = [];
					const start = new Date();
					start.setHours( 0 , 0 , 0 , 0 );
					const end = new Date();
					end.setHours( 23 , 59 , 59 , 999 );
					this.loadMessages( device , `5_${device.informations?.bms}` , {
						start: start.toISOString(),
						end: end.toISOString()
					} , null );
					resolve();
				} );
			} else {
				resolve();
			}
		} );
	}

	loadMessages( device , cbms , period , nextToken ) {
		if( instanceSyncDataManager.observer.props.API ) {
			instanceSyncDataManager.observer.props.API
				.graphql({ query: listMessages, variables: { input: { cbms: cbms , count: 100 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listMessages ) {
						returned.data.listMessages.messages.forEach( message => {
							if( !message.level ) {
								message.level = 0;
							}
							device.messages.push( message );
						} );
						if( returned.data.listMessages.nextToken ) {
							this.loadMessages( device , cbms , period , returned.data.listMessages.nextToken );
						}
					}
				})
				.catch(error => {
					console.log( error );
				})
		}
	}

	loadTechnicalDataForDevice( device ) {
		const latestUse = instanceSyncDataManager.getLatestUse( device.periods );
		if( device.name && latestUse ) {
			if( instanceSyncDataManager.observer.props.API ) {
				instanceSyncDataManager.observer.props.API
					.graphql({ query: getLatestTechnicalData, variables: { cbms: `5_${device.name}`, updatedAt:latestUse } })
					.then( returned => {
						if( returned && returned.data && returned.data.getLatestTechnicalData ) {
							device.technicalData = returned.data.getLatestTechnicalData;
						}
					})
					.catch((error) => {
						console.error("error" , error);
					})
			}
		}
	}

	getLatestUse( periods ) {
		let latest = null;
		if( periods && periods.length > 0 ) {
			periods.forEach( period => {
				const hasOpenPeriod = period.endedAt === null;
				const canUpdateLatest = ! latest || period.endedAt > latest;
				if( hasOpenPeriod ) {
					latest = new Date().toISOString();
				} else if ( canUpdateLatest ) {
					latest = period.endedAt;
				}
			} );
		}
		return latest;
	}

	findPayStationIfExists( boxId , list ) {
		let found = null;
		if( list && list.length > 0 ) {
			list.forEach( candidate => {
				if( candidate.box.id === boxId ) {
					found = candidate;
				}
			} );
		}
		return found;
	}

	addDeviceIfRequired( payStation , record ) {
		let deviceFound = null;
		if( payStation.devices && payStation.devices.length > 0 ) {
			payStation.devices.forEach( device => {
				if( device.id === record["device_id"] ) {
					deviceFound = device;
				}
			} );
		}

		if( ! record["device_name"].endsWith( '_' )  ) {
			if( ! deviceFound && record["device_id"] ) {
				const device = {
					id: record["device_id"],
					name: record["device_name"],
					active: record["device_active"],
					periods: [{ startedAt: record["device_period_start"] , endedAt: record["device_period_end"] }]
				}
				payStation.devices.push( device );
				this.loadTechnicalDataForDevice( device );
			} else {
				if( record["device_id"] && ! this.periodExists( { startedAt: record["device_period_start"] ,
					endedAt: record["device_period_end"] } , deviceFound.periods ) ) {
					deviceFound.periods.push({ startedAt: record["device_period_start"] , endedAt: record["device_period_end"] });
				}
			}
		} else {
			this.closePeriodForInvalidDevice( record["device_id"] );
		}
	}

	closePeriodForInvalidDevice( deviceId ) {
		if( instanceSyncDataManager.observer.props.API ) {
			instanceSyncDataManager.observer.props.API
				.graphql({ query: closeDevicePeriod, variables: { deviceId: deviceId } })
				.then( returned => {
					console.log( 'returned' , returned );
				})
				.catch((error) => {
					console.error("error" , error);
				})
		}
	}

	addTerminalIfRequired( payStation , record ) {
		let terminalFound = null;
		if( payStation.terminals && payStation.terminals.length > 0 ) {
			payStation.terminals.forEach( terminal => {
				if( terminal.id === record["terminal_id"] ) {
					terminalFound = terminal;
				}
			} );
		}

		if( ! terminalFound && record["terminal_id"] ) {
			payStation.terminals.push( {
				id: record["terminal_id"],
				name: record["terminal_name"],
				active: record["terminal_active"],
				periods: [{ startedAt: record["terminal_period_start"] , endedAt: record["terminal_period_end"] }]
			} );
		} else {
			if( record["terminal_id"] && ! this.periodExists( { startedAt: record["terminal_period_start"] ,
												endedAt: record["terminal_period_end"] } , terminalFound.periods ) ) {
				terminalFound.periods.push({ startedAt: record["terminal_period_start"] , endedAt: record["terminal_period_end"] });
			}
		}
	}

	periodExists( candidate , periods ) {
		let exists = false;
		if( periods && candidate && periods.length > 0 ) {
			periods.forEach( period => {
				if( ! exists &&
					period.startedAt === candidate.startedAt &&
					period.endedAt === candidate.endedAt ) {
					exists = true;
				}
			} );
		}
		return exists;
	}

	synchronizeLoading( payStations ) {
		return new Promise( resolve => {
			if ( instanceSyncDataManager.queue.length > 0 ) {
				instanceSyncDataManager.waitQueueResolution( payStations , resolve );
			} else {
				resolve( payStations );
			}
		} );
	}

	waitQueueResolution( payStations , resolve ) {
		if ( instanceSyncDataManager.queue.length > 0 ) {
			setTimeout( () => {
				instanceSyncDataManager.waitQueueResolution( payStations , resolve );
			} , 500 );
		} else {
			resolve( payStations );
		}
	}

	subscribe() {
		this.unsubscribe();
		const registrations = [
			{
				query: subscriptions.onNewState,
				type: 'onNewState'
			},{
				query: subscriptions.onNewTechnicalData,
				type: 'onNewTechnicalData'
			},{
				query: subscriptions.onNewOperation,
				type: 'onNewOperation'
			},{
				query: subscriptions.onNewInventory,
				type: 'onNewInventory'
			},{
				query: subscriptions.onNewNotificationState,
				type: 'onNewNotificationState'
			}
		];
		//onNotificationProgressReceived
		if( this.subscriptions.length < registrations.length ) {
			registrations.forEach( registration => {
				this.notificationHistory[registration.type] = { queue: [] , done: [] };
				this.subscriptions.push( this.getSubscription( registration.query , registration.type ) );
			} );
		}
	}

	getSubscription( subscriptionQuery , type ) {
		return this.observer.props.API
			.graphql({ query: subscriptionQuery })
			.subscribe({
				next: ({ data }) => {
					this.notificationHistory[type].queue.push( data[type] );
					this.notifyForQueue( type ).then( () => {} );
				},
				error: error => {
					this.observer.onSubscriptionError( error );
				}
			});
	}

	notify( type ) {
		return new Promise( resolve => {
			if( instanceSyncDataManager.notificationHistory[type].queue.length > 0 &&
				instanceSyncDataManager.notificationHistory[type].queue[0] ) {
				const item = instanceSyncDataManager.notificationHistory[type].queue[0];
				let uuid = ( item.uuid ) ? item.uuid : item.jobId;
				if( ! uuid ) {
					uuid = factoryUUID.generate();
				}
				if( this.notificationHistory[type].done.includes( uuid ) ) {
					instanceSyncDataManager.notificationHistory[type].queue.shift();
					resolve();
				} else {
					this.observer.onNewSubscriptionReceived( type , item );
					instanceSyncDataManager.notificationHistory[type].done.push( uuid );
					instanceSyncDataManager.notificationHistory[type].queue.shift();
					resolve();
				}
			} else {
				if( ! instanceSyncDataManager.notificationHistory[type].queue[0]  ) {
					instanceSyncDataManager.notificationHistory[type].queue.shift();
				}
				resolve();
			}
		} );
	}

	notifyForQueue( type , resolveRecursive ) {
		return new Promise( resolve => {
			if ( instanceSyncDataManager.notificationHistory[type].queue.length > 0 ) {
				instanceSyncDataManager.notify( type )
					.then( () => {
						instanceSyncDataManager.notifyForQueue( type , resolveRecursive )
							.then( () => {
								resolve();
							} );
					} );
			} else {
				if( resolveRecursive ) {
					resolveRecursive();
				} else {
					resolve();
				}
			}
		} );
	}

	unsubscribe() {
		if( this.subscriptions ) {
			this.subscriptions.forEach( subscription => {
				subscription.unsubscribe();
			} );
			this.subscriptions = [];
		}
	}
}

export default SyncDataManager;
